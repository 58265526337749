import React, { CSSProperties, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import CustomTooltip from "./CustomTooltip";

export interface IModalProps {
  title?: string;
  subtitle?: string;
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  className?: string;
  style?: CSSProperties;
  disableCloseOnOutsideClick?: boolean;
  hideClose?: boolean;
  // Set to true to prevent the autofocus that is set on the first element of a form inside the modal
  focusTitle?: boolean;
  titleClassName?: string;
}

const Modal: React.FC<IModalProps> = ({
  children,
  title = "",
  subtitle = "",
  showModal,
  setShowModal,
  className,
  style = {},
  disableCloseOnOutsideClick,
  hideClose,
  focusTitle = false,
  titleClassName = 'text-lg',
}) => {
  useEffect(() => {
    if (showModal) {
      ReactTooltip.rebuild();
    }
  }, [showModal]);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => !disableCloseOnOutsideClick && setShowModal(false)}
      >
        <div className="flex items-end justify-center min-h-screen h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              style={style}
              className={`relative pt-[18px] p-6 bg-white inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full ${className}`}
            >
              <div className="flex justify-between mb-2">
                <div>
                  {title && (
                    <div
                      className={`leading-6 font-semibold text-primary-text ${titleClassName}`}
                      tabIndex={focusTitle ? 1 : -1}
                    >
                      {title}
                    </div>
                  )}
                  {subtitle && (
                    <div className="text-xs leading-5 font-normal text-secondary-text mt-2">
                      {subtitle}
                    </div>
                  )}
                </div>
                {!hideClose && (
                  <div className="cursor-pointer text-xl" onClick={() => setShowModal(false)}>
                    <X size={18} className="text-holocene-blue" weight="bold" />
                  </div>
                )}
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
