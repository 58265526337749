import { useMutation, useQueries, useQuery } from "react-query";
import deliveryTasksService, {
  ICreateDeliveryTaskRequest,
} from "holocene-services/deliveryTasks.service";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { IDeliveryTaskType } from "holocene-services/deliveryTasks.service/types";

export const useCreateTask = () =>
  useMutation(
    ({ request, deliveryId }: { request: ICreateDeliveryTaskRequest; deliveryId: number }) =>
      deliveryTasksService.createTask(request, deliveryId)
  );

export const useUpdateTask = () =>
  useMutation(
    ({ id, payload }: { id: number; payload: { completed?: boolean; userId?: number } }) =>
      deliveryTasksService.updateTask(id, payload)
  );

export const useGetDeliveryTasks = (type?: IDeliveryTaskType | null) => {
  return useQuery([QUERY_CONSTANTS.DELIVERY_TASKS, type], () =>
    deliveryTasksService.getAllTasks(type || null)
  );
};
