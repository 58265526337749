import { useQuery, useMutation, useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import tradeLanesService, {
  IDutyDetails,
  IHSCodesData,
} from "holocene-services/tradeLanes.service";
import { useMemo } from "react";

type Country = { id: string; officialName: string; title?: string; alpha2Code?: string };
type EncoTerm = { id: string; code: string };

const parseShipmentData = (data: any) => {
  const originDropDown: {
    [index: string]: { text: string; value: string; title?: string; alpha2Code: string };
  } = {};
  const destinationDropdown: {
    [index: string]: { text: string; value: string; title?: string; alpha2Code: string }[];
  } = {};
  const encoDropdown: { [index: string]: { text: string; value: string }[] } = {};
  const tradeLaneObj: { [index: string]: string } = {};
  data.map(
    (item: {
      tradeLaneOriginCountry: Country;
      tradeLaneDestinationCountry: Country;
      incoterm: EncoTerm;
      id: number;
    }) => {
      const { tradeLaneOriginCountry, tradeLaneDestinationCountry, incoterm, id } = item;
      const oId = tradeLaneOriginCountry.id;
      const dId = tradeLaneDestinationCountry.id;
      const eId = incoterm?.id;
      originDropDown[oId] = {
        text: tradeLaneOriginCountry.officialName,
        value: oId,
        title: tradeLaneOriginCountry.officialName,
        alpha2Code: tradeLaneOriginCountry.alpha2Code || "",
      };
      destinationDropdown[oId] = {
        ...destinationDropdown[oId],
        [dId]: {
          text: tradeLaneDestinationCountry.officialName,
          value: dId,
          title: tradeLaneDestinationCountry.officialName,
          alpha2Code: tradeLaneDestinationCountry.alpha2Code || "",
        },
      };
      const encoId = `${oId}-${dId}`;
      const tradeLaneId = `${oId}-${dId}-${eId}`;
      encoDropdown[encoId] = {
        ...encoDropdown[encoId],
        [eId]: { text: incoterm?.code, value: eId },
      };
      tradeLaneObj[tradeLaneId] = item.id.toString();
    }
  );
  const originDD = Object.values(originDropDown);
  const destinationDD: any = {};
  Object.entries(destinationDropdown).map(
    ([key, value]) =>
      (destinationDD[key] = Object.values(value as { text: string; value: string }[]))
  );
  const encoDD: any = {};
  Object.entries(encoDropdown).forEach(
    ([key, value]) => (encoDD[key] = Object.values(value as { text: string; value: string }[]))
  );
  return {
    originDD: originDD,
    destinationDD: destinationDD,
    encoDD: encoDD,
    tradeLaneObj: tradeLaneObj,
  };
};

export const useGetTradeLaneCountries = () => {
  return useQuery(
    [QUERY_CONSTANTS.TRADE_LANE_COUNTRIES],
    async () => {
      const response = await tradeLanesService.getTradeLanesCountries();
      return parseShipmentData(response);
    },
    {
      initialData: {
        originDD: [],
        destinationDD: [],
        encoDD: [],
        tradeLaneObj: {},
      },
      enabled: false,
      refetchOnMount: "always",
    }
  );
};

export const useGetDutyTaxes = (origin: string, destination: string, hsCode?: string | number) => {
  return useQuery<IDutyDetails | null>(
    [QUERY_CONSTANTS.DUTY_TAXES, origin, destination, hsCode],
    async ({ queryKey }) => {
      if (!queryKey[3]) {
        return null;
      }

      const response = await tradeLanesService.getDutyTaxes({
        originCountryAlpha2Code: queryKey[1] as string,
        destinationCountryAlpha2Code: queryKey[2] as string,
        hsCode: queryKey[3] as string,
      });

      return response;
    },
    {
      refetchOnMount: "always",
    }
  );
};

export const useFindHsCodes = (name?: string, destination?: number) => {
  return useQuery<IHSCodesData | undefined>(
    [QUERY_CONSTANTS.FIND_HS_CODES, name, destination],
    async ({ queryKey }) => {
      if (!queryKey[1] || !queryKey[2]) {
        return;
      }
      const response = await tradeLanesService.findHSCodes(
        queryKey[1] as string,
        queryKey[2] as string
      );
      return response;
    },
    {
      enabled: false,
      refetchOnMount: "always",
    }
  );
};

export const useCountPublicTradeLanes = () => {
  return useQuery(
    [QUERY_CONSTANTS.PUBLIC_TRADE_LANE_COUNT],
    () => tradeLanesService.getPublicTradeLaneCount(),
    {
      initialData: 0,
      refetchOnMount: "always",
    }
  );
};

export const useCountPrivateTradeLanes = () => {
  return useQuery(
    [QUERY_CONSTANTS.PRIVATE_TRADE_LANE_COUNT],
    () => tradeLanesService.getPrivateTradeLaneCount(),
    {
      initialData: 0,
      refetchOnMount: "always",
    }
  );
};

export const useCreateShipment = () =>
  useMutation((values) => tradeLanesService.createShipment(values));

export const useGetTradeLaneProjects = (tradeLaneId: number, isConsolidated?: boolean) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [QUERY_CONSTANTS.TRADE_LANE_PROJECTS, tradeLaneId],
    () => tradeLanesService.getTradeLaneProjects(tradeLaneId, isConsolidated)
  );

  const projects = useMemo(
    () =>
      data?.projects.map((proj) => ({
        ...proj,
        projectFiles: proj.projectFiles.map((file) => ({
          fileId: file.id.toString(),
          originalFileName: file.fileName,
        })),
      })),
    [data?.projects]
  );

  return { data: projects, isLoading: isLoading || isFetching, isError, refetch };
};

export const useGetUserForwarders = () => {
  const { data, isLoading, refetch } = useQuery(
    QUERY_CONSTANTS.USER_FORWARDERS,
    tradeLanesService.getUserForwarders
  );

  const options = useMemo(
    () =>
      data?.map((opt) => ({
        label: opt.forwarderName,
        value: opt.id,
        emails: opt.forwarderEmail.map(({ emailId }) => ({ label: emailId, value: emailId })),
      })),
    [data]
  );

  return { data: options || [], isLoading, refetch };
};

export const usePostUserForwarder = () => useMutation(tradeLanesService.postUserForwarder);

export const useGetUserProviders = () => {
  const { data, isLoading, refetch } = useQuery(
    QUERY_CONSTANTS.USER_PROVIDERS,
    tradeLanesService.getUserProviders
  );

  const options = useMemo(
    () =>
      data?.map((opt) => ({
        label: opt.providerName,
        value: opt.id,
        emails: opt.providerEmail.map(({ emailId }) => ({ label: emailId, value: emailId })),
      })),
    [data]
  );

  return { data: options || [], isLoading, refetch };
};

export const usePostUserProvider = () => useMutation(tradeLanesService.postUserProvider);

export const useGetTradeLaneRuleOptions = () => {
  return useQuery(
    QUERY_CONSTANTS.TRADELANE_RULE_OPTIONS,
    tradeLanesService.getTradeLaneRuleOptions
  );
};

export const useGetTradeLaneRules = () => {
  return useQuery(QUERY_CONSTANTS.TRADELANE_RULES, tradeLanesService.getTradeLaneRules);
};

export const useCreateTradeLaneRule = () => {
  return useMutation(tradeLanesService.createTradeLaneRule);
};

export const useGetTradelaneConfig = () => {
  return useQuery(QUERY_CONSTANTS.TRADELANE_CONFIG, tradeLanesService.getTradeLaneConfig);
};

export const useGetMasterTradeLaneConfig = () => {
  return useQuery(
    QUERY_CONSTANTS.MASTER_TRADELANE_CONFIG,
    tradeLanesService.getMasterTradelaneConfig
  );
};

export const usePatchMasterTradeLaneConfig = () => {
  const queryClient = useQueryClient();
  return useMutation(tradeLanesService.patchMasterTradelaneConfig, {
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_CONSTANTS.MASTER_TRADELANE_CONFIG, data);
    },
  });
};
