import Notification from "holocene-components/common/Notification";
import React, { createContext, FC, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export enum INotificationTypes {
  Error = "error",
  Success = "success",
  Info = "info",
  Warning = "warning",
}

export interface INotificationListProps {
  message?: string;
  id: string;
  type: INotificationTypes;
  description?: string;
  time?: number;
  isDeliveryNotification?: boolean;
  showCloseButton?: boolean;
}

export interface NotificationContext {
  notificationList: INotificationListProps[];
  addNotification: (notificationType: Omit<INotificationListProps, "id">) => string;
  removeNotification: (id: string) => void;
  setNotificationList: (notificationType: INotificationListProps[]) => void;
}

export const NotificationContext = createContext<NotificationContext>({
  notificationList: [],
  addNotification: () => "",
  removeNotification: () => {},
  setNotificationList: () => {},
});

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const NotificationProvider: FC<Props> = (props) => {
  const [notificationList, setNotificationList] = useState<INotificationListProps[]>([]);

  const addNotification = (val: Omit<INotificationListProps, "id">): string => {
    const id = uuidv4();
    setNotificationList((p) => [{ ...val, id }, ...p]);
    return id;
  };

  const removeNotification = (uid: string) =>
    setNotificationList((p) => [...p.filter(({ id }) => id !== uid)]);

  return (
    <NotificationContext.Provider
      value={{
        notificationList,
        addNotification,
        removeNotification,
        setNotificationList,
      }}
    >
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        style={{ zIndex: 100 }}
      >
        <div className="w-full flex flex-col items-end space-y-4">
          {notificationList.map(
            ({ message, id, type, description, time, isDeliveryNotification, showCloseButton }) => {
              return (
                <Notification
                  message={message}
                  id={id}
                  key={id}
                  type={type}
                  description={description}
                  time={time}
                  isDeliveryNotification={isDeliveryNotification}
                  showCloseButton={showCloseButton}
                />
              );
            }
          )}
        </div>
      </div>
      {props.children}
    </NotificationContext.Provider>
  );
};
