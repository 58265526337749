import { ISearchResponse } from "holocene-pages/search";
import apiInstance from "holocene-services/axios";
import {
  IDeliveryDetails,
  IDeliveryDetailsWithoutType,
} from "holocene-services/delivery.service/types";

class GlobalSearchService {
  getGlobalSearchByText(
    search: string,
    status: "pending" | "dispatched",
    { skip, take }: { skip: number; take: number }
  ) {
    return apiInstance
      .get(`/global-search?search=${search}&skip=${skip}&take=${take}&status=${status}`)
      .then(
        (res) =>
          res.data as {
            totalCount: number;
            results:
              | (ISearchResponse & { type: "shipment" })
              | (IDeliveryDetailsWithoutType & { type: "delivery" });
          }
      );
  }
}

export const globalSearchService = new GlobalSearchService();
