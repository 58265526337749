import React, { createContext, FC, useContext, useState } from "react";

export interface TaskListContext {
  isTaskListOpen: boolean;
  setIsTaskListOpen: (isTaskListOpen: boolean) => void;
}

export const TaskListContext = createContext<TaskListContext>({
  isTaskListOpen: false,
  setIsTaskListOpen: () => {},
});

export const useTaskList = () => {
  const context = useContext(TaskListContext);
  if (context === undefined) {
    throw new Error("useTaskList must be used within a TaskListProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const TaskListProvider: FC<Props> = (props) => {
  const [isTaskListOpen, setIsTaskListOpen] = useState(false);

  return (
    <TaskListContext.Provider value={{ isTaskListOpen, setIsTaskListOpen }}>
      {props.children}
    </TaskListContext.Provider>
  );
};
