import React, { ChangeEvent, useEffect } from "react";
import { Check, Minus } from "phosphor-react";

const FormCheckbox = ({
  fieldName,
  label,
  className = "",
  formDetails,
  dependency,
  disabled,
  labelClassName,
}: {
  fieldName: string;
  label: string;
  className?: string;
  formDetails: any;
  dependency?: string;
  disabled?: boolean;
  labelClassName?: string;
}) => {
  const { getValues, register, watch, setValue } = formDetails;

  useEffect(() => {
    if (Array.isArray(getValues(dependency))) {
      setValue(fieldName, !!getValues(dependency).length);
    }
  }, [watch(dependency)]); // eslint-disable-line
  return (
    <div className={className}>
      <label className={`${disabled ? "opacity-30" : "cursor-pointer"} relative flex`}>
        <input
          type="checkbox"
          className={`rounded text-holocene-blue border-primary-border focus:ring-0 focus:ring-transparent h-6 w-6 ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          } checked:bg-none`}
          {...register(fieldName)}
          disabled={disabled}
        />
        {watch(fieldName) && (
          <Check className="absolute text-white top-0.5 left-0.5" weight="regular" size={20} />
        )}
        <div
          className={
            "text-primary-text text-sm font-normal ml-3 leading-6 text-clip " +
            (disabled ? "text-secondary-text" : "")
          }
        >
          {label}
        </div>
      </label>
    </div>
  );
};

export const Checkbox = ({
  name,
  label,
  className,
  disabled,
  onChange,
  checked,
  indeterminate,
  size,
  labelClassName,
  disabledClassName,
  checkboxClass="",
}: {
  name?: string;
  label: string | React.ReactElement;
  className?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  checked: boolean;
  size?: "small";
  labelClassName?: string;
  disabledClassName?: string;
  checkboxClass?: string;
}) => {
  return (
    <div className={className}>
      <label
        className={`${
          disabled ? disabledClassName || "opacity-50" : "cursor-pointer"
        } relative flex items-center`}
      >
        <input
          type="checkbox"
          className={`rounded text-holocene-blue border-primary-border focus:ring-0 focus:ring-transparent ${
            size === "small" ? "h-5 w-5" : "h-6 w-6"
          } ${checkboxClass} ${disabled ? "cursor-not-allowed" : "cursor-pointer"} ${
            indeterminate ? "border-holocene-blue" : checked ? "bg-none" : ""
          }`}
          name={name}
          onChange={onChange}
          disabled={disabled}
          checked={indeterminate ? false : checked}
        />
        {indeterminate ? (
          <Minus
            className="absolute text-holocene-blue top-0.5 left-0.5"
            weight="bold"
            size={size === "small" ? 16 : 20}
          />
        ) : null}
        {label && (
          <div
            className={
              "text-primary-text text-sm font-normal ml-3 leading-6 text-clip " +
              (labelClassName || "")
            }
          >
            {label}
          </div>
        )}
      </label>
    </div>
  );
};

export default FormCheckbox;
