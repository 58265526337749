export enum TransportModeName {
  Sea = "sea",
  Air = "air",
  Parcel = "parcel",
  ShippingLabel = "Shipping Label",
  Land = "land",
}

export enum TransportModeNameV2 {
  Sea = "sea",
  Air = "air",
  Parcel = "parcel",
  ShippingLabel = "Shipping Label",
  Rail = "rail",
  Road = "road",
}

export type AnyTransportMode = TransportModeName | TransportModeNameV2;

export interface TransportModeOption {
  label: TransportModeName;
  value: number;
}

export interface TransportModeOptionV2 {
  label: TransportModeNameV2;
  value: number;
}

export enum ShipmentType {
  AirwayBill = "airway_bill",
  Courier = "courier",
  BillOfLading = "bill_of_lading",
  CMR = "CMR",
}
