/* eslint-disable max-len */
import React, { useContext, useMemo } from "react";
import { useGetCountries } from "holocene-hooks/users.hooks";
import { ICountry } from "holocene-services/user.service";
import { CountrySelectMenuOption } from "holocene-components/common/CountrySelector";

export interface ICountriesContext {
  isLoading: boolean;
  countries: ICountry[];
  getCountryfromCountryCode: (countryCode: string) => ICountry | undefined;
  countryOptions: CountrySelectMenuOption[];
}

type ICountriesProvider = {
  children: React.ReactNode;
};

export const CountriesContext = React.createContext<ICountriesContext>({
  isLoading: false,
  countries: [],
  getCountryfromCountryCode: () => {
    return undefined;
  },
  countryOptions: [],
});

export const useCountries = () => {
  const context = useContext(CountriesContext);
  if (context === undefined) {
    throw new Error("useCountries must be used within CountiresProvider");
  }
  return context;
};

export const CountriesProvider: React.FC<ICountriesProvider> = (props) => {
  const { isLoading, data: countries = [] } = useGetCountries();
  const getCountryfromCountryCode = (countryCode: string) => {
    if (!countryCode) {
      return;
    }
    return countries.find((c: ICountry) => c.alpha2Code === countryCode);
  };

  const countryOptions = useMemo(() => {
    return countries.map((country) => ({
      label: country.officialName,
      value: country.id,
      alphaCode: country.alpha2Code,
    }));
  }, [countries]);

  return (
    <CountriesContext.Provider
      value={{
        countries: countries || [],
        isLoading,
        getCountryfromCountryCode,
        countryOptions,
      }}
    >
      {props.children}
    </CountriesContext.Provider>
  );
};
