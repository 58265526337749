/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from "react";
import { useCountTradePolicies } from "holocene-hooks/users.hooks";
import {
  useCountPublicTradeLanes,
  useCountPrivateTradeLanes,
} from "holocene-hooks/tradeLanes.hooks";
import { useCountShipments } from "holocene-hooks/shipments.hooks";

type ShipmentCountType = {
  allShipments: number;
  completedShipmentCount: number;
  delayedShipmentCount: number;
  dispatchedShipmentCount: number;
};
export interface ICountsContext {
  isTPCountLoading: boolean;
  totalTradePolicies: number;
  isPublicTLCountLoading: boolean;
  publicTradeLaneCount: number;
  isPrivatTLCountLoading: boolean;
  privateTradeLaneCount: number;
  tradeLanesLastUpdated: string;
  incrementPrivateTradeLaneCount: (count: number) => void;
  getShipmentCountData: () => void;
  shipmentCount: ShipmentCountType | undefined;
}
type ICountsProvider = {
  children: React.ReactNode;
};
export const CountsContext = React.createContext<ICountsContext>({
  isTPCountLoading: false,
  totalTradePolicies: 0,
  isPublicTLCountLoading: false,
  publicTradeLaneCount: 0,
  isPrivatTLCountLoading: false,
  privateTradeLaneCount: 0,
  tradeLanesLastUpdated: "",
  incrementPrivateTradeLaneCount: () => {},
  getShipmentCountData: () => {},
  shipmentCount: undefined,
});

export const useCounts = () => {
  const context = useContext(CountsContext);
  if (context === undefined) {
    throw new Error("useCounts must be used within CountsProvider");
  }
  return context;
};

export const CountsProvider: React.FC<ICountsProvider> = (props) => {
  const { data: tradePolicies, isLoading: isTPCountLoading } = useCountTradePolicies();
  const { data: publicTradeLaneCount, isLoading: isPublicTLCountLoading } =
    useCountPublicTradeLanes();
  const { data: privateTradeLanes, isLoading: isPrivatTLCountLoading } =
    useCountPrivateTradeLanes();
  const {
    data: shipmentCount,
    isLoading: isShipmentCountLoading,
    refetch: refetchShipmentCount,
  } = useCountShipments();

  const [privateTradeLaneCount, setPrivateTradeLaneCount] = useState<number>(0);

  useEffect(() => {
    setPrivateTradeLaneCount(privateTradeLanes);
  }, [privateTradeLanes]);

  const incrementPrivateTradeLaneCount = (count: number) => {
    setPrivateTradeLaneCount(privateTradeLaneCount + count);
  };

  const getShipmentCountData = () => {
    if (isShipmentCountLoading) {
      return;
    }
    refetchShipmentCount();
  };

  return (
    <CountsContext.Provider
      value={{
        totalTradePolicies: tradePolicies ? tradePolicies.count : 0,
        isTPCountLoading,
        tradeLanesLastUpdated: tradePolicies ? tradePolicies.lastupdated : "",
        publicTradeLaneCount,
        privateTradeLaneCount,
        isPublicTLCountLoading,
        isPrivatTLCountLoading,
        incrementPrivateTradeLaneCount,
        getShipmentCountData,
        shipmentCount,
      }}
    >
      {props.children}
    </CountsContext.Provider>
  );
};
