import React from "react";
import { Switch } from "@headlessui/react";

interface Props {
  label: string;
  className?: string;
  checkedClassName?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const CustomSwitch: React.FC<Props> = ({
  label,
  className = "",
  checked,
  onChange,
  checkedClassName,
  disabled,
}) => (
  <Switch.Group>
    <div className={`flex items-center h-[38px] ${className}`}>
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          !disabled && checked ? "bg-holocene-blue " + checkedClassName : "bg-secondary-text"
        } ${disabled ? "opacity-50" : ""} relative inline-flex h-2 w-8 items-center rounded-full`}
        disabled={disabled}
      >
        <span
          className={`${
            checked ? "translate-x-4" : "translate-x-0"
          } inline-block h-4 w-4 transform rounded-full bg-white shadow-[0px_1px_4px_rgba(0,0,0,0.25)] transition ease-in-out duration-200`}
        />
      </Switch>
      <Switch.Label className="ml-2 cursor-pointer">{label}</Switch.Label>
    </div>
  </Switch.Group>
);

export default CustomSwitch;
