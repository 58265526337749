import {
  TradeLaneStep,
  TradeLaneSubStep,
} from "holocene-providers/tradeLanes/TradeLaneFormContext";
import apiInstance from "holocene-services/axios";
import {
  ICreateTradeLaneReq,
  IDutyDetails,
  IHSCodesData,
  IPrivateTradeLaneNote,
  IPublicTradeLane,
  IPublicTradeLaneDocument,
  IPublicTradeLaneResponse,
  ITLRequestDocument,
  ITradeLane,
  ITradeLaneDocumentBase,
  ITradeLaneResponse,
  RecommendationType,
  GetPrivateTradeLanesParams,
  GetTradeLaneDefParams,
  GetTradeLaneDefTransportModeParams,
  GetTradeLaneDefTransportModeResponse,
  GetTradeLaneDefResponse,
  PostTradeLanePayload,
  TradeLaneProject,
  PostProjectPayload,
  UserForwarder,
  UserProvider,
  TradeLaneRuleOptions,
  TradeLaneRule,
  CreateTradeLaneRuleFormState,
  GetTradeLaneConfigResponse,
  TradeLaneMOTConfig,
  PostTradeLaneMOTConfig,
  PatchMasterTradeLaneConfig,
  MasterTradeLaneConfig,
  TradeLaneFormState,
  TradeLaneSectionProps,
  TransportConfigSection,
  CreateTradeLaneRulePayload,
} from "./types";
import {
  TransportModeName,
  TransportModeNameV2,
} from "holocene-components/common/new/TransportIcon";

class TradeLanesService {
  async createHSCode(data: { description: string; hsCodes: string[] }) {
    const requestData = data.hsCodes.map((hsCode) => ({
      description: data.description,
      hsCode,
    }));

    const response = await apiInstance.post(`/hscodes`, requestData);
    return response.data;
  }

  async createDocument(data: {
    originCountryId?: number;
    destCountryId?: number;
    documentTypeId: number;
    documentName: string;
    issuingBody: string;
    isStatic: boolean;
  }) {
    const response = await apiInstance.post(`/documents`, data);
    return response.data;
  }

  async createTradeLane({ payload }: { payload: PostTradeLanePayload }) {
    const response = await apiInstance.post(`/trade-lanes`, payload);
    return response.data;
  }

  // temporarily creating multiple async calls - multiple incoterms
  async createTradeLanes(requestDataItems: ICreateTradeLaneReq[]) {
    const requests = requestDataItems.map((data) => apiInstance.post(`/trade-lanes`, data));
    return await Promise.allSettled(requests);
  }

  async updateTradeLane({
    payload,
    tradeLaneId,
  }: {
    payload: PostTradeLanePayload;
    tradeLaneId: number;
  }) {
    const response = await apiInstance.patch(`/trade-lanes/${tradeLaneId}`, payload);
    return response.data;
  }

  async getPrivateTradeLanes({
    skip = 0,
    take = 15,
    groupBy = true,
    includeParentCustomer = true,
    destinationCountryIds,
    originCountryIds,
    incotermIds,
    transportIds,
    operationTypes,
    projectIds,
    customerIds,
  }: GetPrivateTradeLanesParams) {
    const response = await apiInstance.get<ITradeLaneResponse>(`/trade-lanes`, {
      params: {
        skip,
        take,
        groupBy,
        includeParentCustomer,
        destinationCountryIds,
        originCountryIds,
        incotermIds,
        transportIds,
        operationTypes,
        projectIds,
        customerIds,
      },
    });
    return response.data;
  }

  async deletePrivateTradeLane(tradeLaneId: number) {
    const response = await apiInstance.delete(`/trade-lanes/${tradeLaneId}`);
    return response.data;
  }

  async getPublicTradeLanes(skip: number = 0, take: number = 15, searchString: string = "") {
    const response = await apiInstance.get<IPublicTradeLaneResponse>(`/public-trade-lanes`, {
      params: {
        skip,
        take,
        searchString,
      },
    });
    const data = response.data;
    return {
      count: data.count,
      tradeLanes: data.publicTradeLanes.map((lane, index) => ({
        id: lane.id,
        destinationCountryId: lane.destinationCountryId,
        originCountryId: lane.originCountryId,
        policyCount: 0,
        productDifferentOrigin: lane.productDifferentOrigin,
        incoterm: lane.incoterm,
        tradeLaneDestinationCountry: lane.destinationCountry,
        tradeLaneOriginCountry: lane.originCountry,
        tradeLaneHSCodes: lane.publicTradeLaneHSCodes,
        tradeLaneDocuments: lane.publicTradeLaneDocuments,
      })),
    };
  }

  async getPublicTradeLane(id: number) {
    const response = await apiInstance.get<IPublicTradeLane>(`/public-trade-lanes/${id}`);
    const data = response.data;

    return {
      destination: data.destinationCountry.alpha2Code,
      origin: data.originCountry.alpha2Code,
      productDifferentOrigin: data.productDifferentOrigin,
      incoterms: [{ value: data.incoterm.id, label: data.incoterm.code }],
      hsCodeIds: data.publicTradeLaneHSCodes.map(({ hsCode: { id, hsCode } }) => ({
        hsCodeId: id,
        hsCode: hsCode,
      })),
      documents: data.publicTradeLaneDocuments, // setting this temporarily - documentTypes are not expected to be available at the time of this data getting fetched
    };
  }

  async getPrivateTradeLane(id: number) {
    return apiInstance.get<ITradeLane>(`/trade-lanes/${id}`).then((res) => res.data);
  }

  async getRecommendations(params: {
    type: RecommendationType;
    skip?: number;
    take?: number;
    originId: number;
    destinationId: number;
    hsCodeIds?: number[];
    interventionTypes?: string[];
    incotermIds?: number[];
    groupBy?: boolean;
  }) {
    const response = await apiInstance.get(`/trade-lanes/recommendations`, {
      params: params,
    });
    return response.data;
  }

  async getDutyTaxes(params: {
    hsCode: string;
    originCountryAlpha2Code: string;
    destinationCountryAlpha2Code: string;
  }) {
    const response = await apiInstance.get<IDutyDetails>(`/trade-lanes/duty-taxes`, {
      params: params,
    });
    return response.data;
  }

  async findHSCodes(name: string, destination: string) {
    const response = await apiInstance.get("/hscodes/search", {
      params: {
        name,
        destCountry2DCode: destination,
        numberOfTopHSCodesToReturn: 3,
      },
    });
    return response.data;
  }

  async getTradeLanesCountries() {
    const response = await apiInstance.get(`/trade-lanes/country`);
    return response.data;
  }

  async createShipment(data: any) {
    const response = await apiInstance.post(`/shipments`, data);
    return response.data;
  }

  async getPublicTradeLaneCount() {
    const response = await apiInstance.get(`/public-trade-lanes/count`);
    return response.data;
  }

  async getPrivateTradeLaneCount() {
    const response = await apiInstance.get(`/trade-lanes/count?type=private`);
    return response.data;
  }

  //====== TRADE LANE DEFINITION AND STEPS ========

  async getTradelaneDef(
    params: GetTradeLaneDefParams
  ): Promise<GetTradeLaneDefResponse | undefined> {
    if (!params.incotermId || !params.modeOfTransportIds || !params.shippingOperationType)
      return undefined;
    return apiInstance.get("/trade-lane-def", { params }).then((res) => res.data);
  }

  async getTradeLaneDefTransportModes(
    params: GetTradeLaneDefTransportModeParams
  ): Promise<GetTradeLaneDefTransportModeResponse[]> {
    if (!params.incotermId || !params.shippingOperationType) {
      return [];
    }
    return apiInstance.get("/trade-lane-def/transport-modes", { params }).then((res) => res.data);
  }

  getTradelaneSteps(id: number): Promise<{ steps: TradeLaneStep[]; def: { id: number } }> {
    return apiInstance.get(`/trade-lanes/${id}/steps`).then((res) => res.data);
  }

  //====== TRADE LANE PROJECTS ========

  getTradeLaneProjects(
    tradeLaneId: number,
    isConsolidated?: boolean
  ): Promise<{ projects: TradeLaneProject[]; count: number }> | undefined {
    if (isConsolidated) return undefined;
    return apiInstance
      .get(`/trade-lane-projects`, { params: { tradeLaneId: tradeLaneId, skip: 0, take: 25 } })
      .then((res) => res.data);
  }

  postTradeLaneProject(payload: PostProjectPayload) {
    return apiInstance.post("/trade-lane-projects", payload);
  }

  patchTradeLaneProject({ payload, id }: { payload: PostProjectPayload; id: number }) {
    return apiInstance.patch(`/trade-lane-projects/${id}`, payload);
  }

  deleteTradeLaneProject(id: number) {
    return apiInstance.delete(`/trade-lane-projects/${id}`);
  }

  //====== TRADE LANE FORWARDERS ========

  getUserForwarders(): Promise<UserForwarder[]> {
    return apiInstance
      .get("/user-forwarders", { params: { skip: 0, take: 1000 } })
      .then((res) => res.data);
  }

  postUserForwarder(forwarderName: string) {
    return apiInstance.post("/user-forwarders", { forwarderName });
  }

  postUserForwarderEmail(payload: { userForwarderId: number; emailId: string }) {
    return apiInstance.post("/user-forwarders/email", payload);
  }
  //====== TRADE LANE PROVIDERS ========
  getUserProviders(): Promise<UserProvider[]> {
    return apiInstance
      .get("/user-providers", { params: { skip: 0, take: 1000 } })
      .then((res) => res.data);
  }

  postUserProvider(providerName: string) {
    return apiInstance.post("/user-providers", { providerName });
  }

  postUserProviderEmail(payload: { userProviderId: number; emailId: string }) {
    return apiInstance.post("/user-providers/email", payload);
  }
  //====== TRADE LANE OTHER DOCUMENTS ========

  postTradeLaneOtherDocument({
    payload,
    tradeLaneId,
  }: {
    payload: TradeLaneSubStep;
    tradeLaneId: number;
  }) {
    return apiInstance.post(`/trade-lanes/${tradeLaneId}/steps/other`, payload);
  }

  deleteTradeLaneOtherDocument(doc: { id: number; tradeLaneId: number }) {
    return apiInstance.delete(`/trade-lanes/${doc.tradeLaneId}/steps/other/${doc.id}`);
  }

  //====== TRADE LANE PROJECT FILES ========

  uploadTradeLaneProjectFile({ file, projectId }: { file: File; projectId: number }) {
    const formdata = new FormData();
    formdata.append("file", file);
    return apiInstance.post(`/trade-lane-projects/${projectId}/upload`, formdata);
  }

  deleteTradeLaneProjectFile(id: string | number) {
    return apiInstance.delete(`/trade-lane-projects/project-files/${id}`);
  }

  getTradeLaneProjectFile(id: string | number) {
    return apiInstance.get(`/trade-lane-projects/project-files/${id}`);
  }

  getTradeLaneRuleOptions() {
    return apiInstance
      .get("/trade-lane-rules/creation-primitives")
      .then((response) => response.data as TradeLaneRuleOptions);
  }

  getTradeLaneRules() {
    return apiInstance
      .get("/trade-lane-rules")
      .then((response) => response.data as { rules: TradeLaneRule[] });
  }

  createTradeLaneRule(payload: CreateTradeLaneRulePayload) {
    return apiInstance.post("/trade-lane-rules", payload);
  }

  getTradeLaneConfig() {
    return apiInstance
      .get("/trade-lane-config/documents")
      .then((res) => res.data as GetTradeLaneConfigResponse);
  }

  postTradeLaneMOTConfig(payload: PostTradeLaneMOTConfig) {
    return apiInstance.post("/trade-lane-config/documents", payload);
  }

  deleteTradeLaneRule(id: number) {
    return apiInstance.delete(`/trade-lane-rules/${id}`);
  }

  patchMasterTradelaneConfig(payload: PatchMasterTradeLaneConfig) {
    return apiInstance
      .patch(`/trade-lane-config`, payload)
      .then((res) => res.data as MasterTradeLaneConfig);
  }

  getMasterTradelaneConfig() {
    return apiInstance.get("/trade-lane-config").then((res) => res.data as MasterTradeLaneConfig);
  }
}

export default new TradeLanesService();

export { RecommendationType };

export type {
  ITLRequestDocument,
  ITradeLane,
  IPrivateTradeLaneNote,
  ITradeLaneDocumentBase,
  IPublicTradeLaneDocument,
  IHSCodesData,
  IDutyDetails,
  TradeLaneFormState,
  TradeLaneSectionProps,
  TransportConfigSection,
};
