import {
  IShipmentEntry,
  IShipmentTableData,
  IShipmentTableRow,
} from "holocene-services/shipment.service";
import {
  IShipment,
  ShipmentInstructions,
  ShipmentWithStatus,
} from "holocene-services/shipment.service/types";
import moment from "moment";
import { formatMomentDate } from "./date.utils";
import { createDeepCopy } from "./helpers";

/**Updates the query data for shipments based on any local edits -
 * based on whether it is deleted or edited
 *
 * 1. create a deep copy of the oldPages i.e. the existing query data
 * 2. find the shipment that matches the shipmentId passed in params
 * 3. If the entry is deleted, remove it from the list and update the counts
 *    else update the entry with the payload
 */
export const updateShipmentQueryPages = (
  oldData: IShipmentTableData,
  shipmentId: number,
  options: { payload?: IShipmentEntry; isDeleted?: boolean; appendData?: IShipmentTableData }
) => {
  let data = createDeepCopy<IShipmentTableData>(oldData);

  const { payload, isDeleted } = options;
  if (options.appendData) {
    const newShipments = Object.entries(options.appendData.shipments);
    for (let j = 0; j < newShipments.length; j++) {
      let [key, shipment] = newShipments[j];
      data.shipments[key] = {
        ...data.shipments[key],
        shipments: [...data.shipments[key].shipments, ...shipment.shipments],
        consolidatedShipments: [
          ...data.shipments[key].consolidatedShipments,
          ...shipment.consolidatedShipments,
        ],
        hasMore: shipment.hasMore,
      };
    }
    return data;
  }
  const shipmentEntries = Object.entries(data.shipments);

  for (let j = 0; j < shipmentEntries.length; j++) {
    let [key, shipment] = shipmentEntries[j];
    let itemIndex = shipment.shipments.findIndex((item) => item.id === shipmentId);
    if (itemIndex > -1) {
      let shipmentKey = key;
      if (isDeleted) {
        let shipmentGroup = data.shipments[shipmentKey];
        if (shipmentGroup.shipments.length === 1) {
          delete data.shipments[shipmentKey];
        } else {
          shipmentGroup.shipments.splice(itemIndex, 1);
        }
      } else if (payload) {
        let oldShipment = data.shipments[shipmentKey].shipments[itemIndex];
        data.shipments[shipmentKey].shipments[itemIndex] = {
          ...oldShipment,
          ...payload,
        };
      }
      return data;
    }
  }

  return data;
};

/**This function returns the grouped shipments in the required tabular format. It also
 * returns the count of the fetched shipments so far.
 */
export const extractShipmentsAndCountFromQuery = (
  queryPages: IShipmentTableData[]
): { count: number; shipments: IShipmentTableRow[] } => {
  let shipmentsDataByCountries: {
    [key: string]: IShipmentTableRow;
  } = {};
  let count = 0;

  queryPages.forEach(({ shipments }) => {
    Object.entries(shipments).forEach(([key, value]) => {
      const [origin, destination] = key.split("-");

      if (shipmentsDataByCountries[key]) {
        shipmentsDataByCountries[key] = {
          ...shipmentsDataByCountries[key],
          consolidatedShipments: [
            ...shipmentsDataByCountries[key].consolidatedShipments,
            ...value.consolidatedShipments,
          ],
          hasMore: value?.hasMore,
          shipments: [...shipmentsDataByCountries[key].shipments, ...value.shipments],
        };
      } else {
        shipmentsDataByCountries[key] = {
          origin,
          destination,
          consolidatedShipments: value.consolidatedShipments,
          shipments: value.shipments,
          hasMore: value?.hasMore,
          shipmentsCount: value?.shipmentsCount || 0,
          consolidationsCount: value?.consolidationsCount || 0,
        };
      }
      if (value.consolidatedShipments.length) {
        count = count + 1;
      }
      count += value.shipments.length;
    });
  });
  return { count, shipments: Object.values(shipmentsDataByCountries) };
};
export const extractShipmentsAndCountFromQuery2 = (
  data: any
): { count: number; shipments: IShipmentTableRow[] } => {
  let shipmentsDataByCountries: {
    [key: string]: IShipmentTableRow;
  } = {};
  let count = 0;

  Object.entries(data).forEach(([key, value]: [key: string, value: any]) => {
    const [origin, destination] = key.split("-");
    count = count + value.shipmentsCount + value.consolidationsCount;
    shipmentsDataByCountries[key] = {
      consolidatedShipments: value.consolidatedShipments,
      shipments: value.shipments,
      origin: origin,
      destination: destination,
      hasMore: value.hasMore,
      shipmentsCount: value.shipmentsCount,
      consolidationsCount: value.consolidationsCount,
    };
  });
  return { count, shipments: Object.values(shipmentsDataByCountries) };
};

export const getETADetails = (shipment: ShipmentWithStatus | IShipment) => {
  const { etaOld, etaNew } = shipment;
  return compareETA(etaOld, etaNew);
};

export const compareETA = (etaOld: string, etaNew: string) => {
  if (!etaNew && !etaOld) return { delayText: "", eta: "" };
  if (!etaNew) {
    return { delayText: "On Schedule", eta: "" };
  }
  const oldDate = moment(etaOld);
  const newDate = moment(etaNew);
  const changeInEta = newDate.diff(oldDate) !== 0;
  const isDelayed = newDate.diff(oldDate) > 0;
  const delayText = changeInEta ? (isDelayed ? "Delayed" : "Early") : "On Schedule";

  return {
    delayText,
    eta: formatMomentDate(moment(etaNew).utc()),
    etaDiffInDays: newDate.diff(oldDate, "days"),
  };
};

export const generateShipmentTemplate = (shipmentInstructions?: ShipmentInstructions): string => {
  if (!shipmentInstructions) {
    return "";
  }

  return `
    Qty of containers required: ${
      shipmentInstructions.equipmentDetails?.map((detail) => detail + ", ") || "-"
    }<br />
    CBM: ${shipmentInstructions.total_cbm?.toFixed(2) || ""}<br />
    Net Weight: ${shipmentInstructions.netWeight?.toFixed(2)}<br /><br />
    Description of Goods: ${
      shipmentInstructions.products?.map((product) => product.DESCRIPTION + ", ") || "-"
    }<br /><br />

    Pickup Location: ${shipmentInstructions.pickupName} - ${
    shipmentInstructions.pickupAddress
  }<br />
    Pickup Date:<br />
    Consignee: ${shipmentInstructions.consigneeName || ""} - ${
    shipmentInstructions.consigneeAddress || ""
  }<br />
    Notify: ${shipmentInstructions.notifierName || ""} ${
    shipmentInstructions.notifierAddress?.country?.officialName || ""
  }<br />
    Terms: ${shipmentInstructions.incoterm}
  `;
};
