import { PresentationChart, Gear, Path, IconProps, ListNumbers, Package } from "phosphor-react";
import Tower from "public/icons/svg/Tower.svg";
import ClipboardChecks from "public/icons/svg/ClipboardChecks.svg";
import Dispatched from "public/icons/svg/Dispatched.svg";
import { getOperationRoute, routes } from "holocene-constants/routes";
import Link from "next/link";
import { useRouter } from "next/router";
import { useActiveOperation } from "holocene-hooks/utils.hooks";
import { useUserDetails } from "holocene-providers/common";
import { useEffect, useMemo } from "react";
import { useGetCurrentUserInfo } from "holocene-hooks/users.hooks";
import { FeaturePermissionEnum } from "holocene-services/user.service/types";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";
import { isDevelopmentOrStagingEnv } from "holocene-utils/common.utils";

export type IMenuItem = {
  icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  path: string;
  text: string;
  disabled?: boolean;
  activationPaths: string[];
};
const MenuItem = ({ menu }: { menu: IMenuItem }) => {
  const { pathname } = useRouter();

  const { icon, path, text, activationPaths } = menu;
  const MenuIcon = icon;

  const isActive = activationPaths.some((activationPath) => pathname.includes(activationPath));

  return (
    <Link
      key={text}
      href={menu.disabled ? window.location.pathname : `${path}`}
      passHref
      aria-disabled={menu.disabled}
      legacyBehavior
    >
      <a
        className={`cursor-pointer w-10 h-10 z-50 flex items-center p-2.5 group hover:w-max hover:${
          isActive ? "bg-blue-1" : "bg-ice-blue-2"
        } rounded-lg mb-2 ${isActive ? "bg-blue-1 text-white" : ""} ${
          menu.disabled ? "cursor-default" : ""
        }`}
      >
        <MenuIcon className={`text-navy-4`} weight="regular" size={20} />
        <div className={`text-xs text-navy-4 hidden group-hover:block ml-2 mr-1`}>{text}</div>
      </a>
    </Link>
  );
};
const Sidebar = () => {
  const { pathname } = useRouter();
  const activeOperation = useActiveOperation();
  const { operationType } = useUserDetails();
  const router = useRouter();
  const { data: userInfo } = useGetCurrentUserInfo();

  const generateMenuList = (permissionMap: Record<string, boolean>) => {
    const isSupportUser = userInfo?.roles.includes("Admin");
    const updatedMenuList: IMenuItem[] = [
      ...(isSupportUser || permissionMap[FeaturePermissionEnum.CONTROL_TOWER]
        ? [
            {
              icon: Tower,
              path: getOperationRoute("controlTower", activeOperation),
              text: "Control Tower",
              activationPaths: ["sales/control-tower", "purchase/control-tower"],
            },
            ...(isDevelopmentOrStagingEnv() && activeOperation === ModuleEnum.purchase
              ? [
                  {
                    icon: () => (
                      <div className="relative text-navy-4">
                        <Tower size={20} />
                        <div className="text-[10px]  absolute -top-1 -right-1">3</div>
                      </div>
                    ),
                    path: `/purchase/v3/control-tower`,
                    text: "Control Tower v3",
                    activationPaths: ["v3/control-tower"],
                  },
                ]
              : []),
          ]
        : []),
      ...(activeOperation === ModuleEnum.sales &&
      (isSupportUser || permissionMap[FeaturePermissionEnum.PERFORMANCE])
        ? [
            {
              icon: PresentationChart,
              path: getOperationRoute("performanceDashboard", activeOperation),
              text: "Performance Dashboard",
              activationPaths: ["performance-dashboard"],
            },
          ]
        : []),
      ...(isSupportUser || permissionMap[FeaturePermissionEnum.DISPATCH]
          ? [
              ...(isSupportUser || permissionMap[FeaturePermissionEnum.DELIVERY]
                ? [
                    {
                      icon: ClipboardChecks,
                      path:
                        activeOperation === ModuleEnum.sales
                          ? routes.deliveryQueue
                          : getOperationRoute("shipmentsInQueue", activeOperation),
                      text: "Open Deliveries",
                      activationPaths: ["in-queue"],
                    },
                  ]
                : []),
              ...(isSupportUser || permissionMap[FeaturePermissionEnum.DISPATCH]
                ? [
                    {
                      icon: Dispatched,
                      path:
                        activeOperation === ModuleEnum.sales
                          ? routes.deliveryDispatched
                          : getOperationRoute("shipmentsDispatched", activeOperation),
                      text: "Dispatched Deliveries",
                      activationPaths: ["dispatched", "delivered"],
                    },
                  ]
                : []),
            ]
        : []),
      ...(activeOperation === ModuleEnum.purchase && isDevelopmentOrStagingEnv()
        ? [
            ...(isSupportUser || permissionMap[FeaturePermissionEnum.PURCHASE_ORDERS]
              ? [
                  {
                    icon: () => (
                      <div className="relative text-navy-4">
                        <ListNumbers size={20} />
                        <div className="text-[10px]  absolute -top-1 -right-1">3</div>
                      </div>
                    ),
                    path: getOperationRoute("shipmentsOrderList", activeOperation),
                    text: "Orders List",
                    activationPaths: ["orders"],
                  },
                ]
              : []),
            ...(isSupportUser || permissionMap[FeaturePermissionEnum.DELIVERY]
              ? [
                  {
                    icon: () => (
                      <div className="relative text-navy-4">
                        <Package size={20} />
                        <div className="text-[10px]  absolute -top-1 -right-1">3</div>
                      </div>
                    ),
                    path: getOperationRoute("ShipmentDeliveryOrdersList", activeOperation),
                    text: "Inbound Delivery List",
                    activationPaths: ["inbound", "delivery"],
                  },
                ]
              : []),
          ]
        : []),
      ...(isSupportUser || permissionMap[FeaturePermissionEnum.TRADE_LANES]
        ? [ 
            {
              icon: Path,
              path: getOperationRoute("tradelanes", activeOperation),
              text: "Trade Lanes",
              activationPaths: ["sales/trade-lanes", "purchase/trade-lanes"],
            },
            ...(isDevelopmentOrStagingEnv() && activeOperation === ModuleEnum.purchase
              ? [
                  {
                    icon: () => (
                      <div className="relative text-navy-4">
                        <Path size={20} />
                        <div className="text-[10px]  absolute -top-1 -right-1">3</div>
                      </div>
                    ),
                    path: `/purchase/v3/trade-lanes`,
                    text: "Trade Lanes v3",
                    activationPaths: ["v3/trade-lanes"],
                  },
                ]
              : []),
          ]
        : []),
      ...(isSupportUser || permissionMap[FeaturePermissionEnum.SETTINGS]
        ? [
            {
              icon: Gear,
              path: getOperationRoute("settings", activeOperation),
              text: "Settings",
              activationPaths: ["settings"],
            },
          ]
        : []),
    ];
    return updatedMenuList;
  };

  const generatePermissionMap = () => {
    const currentModule = activeOperation;
    const mappedData = userInfo?.detailInfo.UserAssignedFeaturePermissions.filter(
      ({ FeaturePermission }) => FeaturePermission.moduleName === currentModule
    ).reduce((acc: Record<string, boolean>, permissionInfo) => {
      if (
        (permissionInfo.readable || permissionInfo.writable) &&
        !acc[permissionInfo.FeaturePermission.feature]
      ) {
        acc[permissionInfo.FeaturePermission.feature] = true;
      }
      return acc;
    }, {});
    return mappedData;
  };

  const menuList = useMemo(() => {
    const updatedPermissionMap = generatePermissionMap() ?? {};
    const updatedMenuList = generateMenuList(updatedPermissionMap);
    return updatedMenuList;
  }, [activeOperation, operationType, userInfo]);

  useEffect(() => {
    if (operationType && activeOperation !== operationType) {
      router.push(getOperationRoute("controlTower", operationType));
    }
  }, [activeOperation, operationType]);

  return (
    <div className="w-[60px] border-r border-strokes px-2.5 flex flex-col justify-center">
      {menuList.map((menu) => (
        <MenuItem menu={menu} key={menu.text} />
      ))}
    </div>
  );
};
export default Sidebar;
