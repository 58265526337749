import { createContext, useCallback, useContext, useRef, useState } from "react";

interface DocumentUploadContextValue {
  loading: boolean;
  resetContext: () => void;
  triggerLoading: () => void;
}

export const DocumentUploadContext = createContext<DocumentUploadContextValue>({
  loading: false,
  resetContext: () => {},
  triggerLoading: () => {},
});

export const useDocumentUploadLoader = () => {
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetContext = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(false);
  }, []);

  const triggerLoading = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(true);
    timeoutRef.current = setTimeout(resetContext, 30000);
  };

  return {
    loading,
    triggerLoading,
    resetContext,
  };
};

export const DocumentUploadProvider: React.FC<{}> = ({ children }) => {
  const contextValue = useDocumentUploadLoader();
  return (
    <DocumentUploadContext.Provider value={contextValue}>{children}</DocumentUploadContext.Provider>
  );
};

export const useDocumentUploadContext = () => {
  const context = useContext(DocumentUploadContext);
  if (!context) {
    throw new Error("useDocumentUploadContext must be used inside DocumentUploadProvider");
  }

  return context;
};
