import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CaretDown, CaretUp } from "phosphor-react";

interface CustomDropdownProps {
  onChange: Function;
  classes?: String;
  defaultValue?: String | Number;
  value?: String | Number;
  data: string[] | number[];
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  data,
  onChange,
  classes,
  defaultValue,
  value,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (newValue: string | number) => {
    setSelected(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div>
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <div className={`relative mt-1 ${classes}`}>
            <Listbox.Button className="relative w-full cursor-pointer sm:text-sm date-input">
              <span className="block">{selected}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {open ? <CaretUp size={12} /> : <CaretDown size={12} />}
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-64 w-full pt-1 overflow-auto rounded-md bg-white pb-2.5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map((value: string | number, valueIdx: number) => (
                  <Listbox.Option
                    key={valueIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none  text-xs  font-normal py-0.5 px-3.5 text-holocene-blue ${
                        active ? "font-semibold" : ""
                      }`
                    }
                    value={value}
                  >
                    {({ selected }) => (
                      <>
                        <span>{value}</span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default CustomDropdown;
