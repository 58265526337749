import { UserCircle } from "phosphor-react";
import React, { ForwardedRef, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";

interface IProps {
  name: string;
  size?: number;
  className?: string;
  onClick?: () => void;
  style?: any;
  showDefault?: boolean;
}

const UserAvatar = React.forwardRef(
  (
    { name, size = 8, className = "", showDefault = false, ...props }: IProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, []);

    const initials = useMemo(() => {
      const nameToSplit = name ?? "";
      const [firstName, lastName] = nameToSplit.split(" ");
      return (
        (firstName && firstName.length ? firstName.substring(0, 1) : "") +
        (lastName && lastName.length ? lastName.substring(0, 1) : "")
      );
    }, [name]);

    let sizeClass = "";
    if (size === 8) {
      sizeClass = "w-8 h-8 min-w-8 max-w-8 leading-8";
    } else if (size === 7) {
      sizeClass = "w-7 h-7 min-w-7 max-w-7 leading-7";
    } else if (size === 6) {
      sizeClass = "w-6 h-6 min-w-6 max-w-6 leading-6";
    }

    return (
      <div
        className={`flex items-center justify-center bg-lumber text-holocene-blue font-regular text-xs uppercase rounded-full text-center ${sizeClass} ${className}`}
        data-tip={name}
        ref={ref}
        {...props}
      >
        {initials || (showDefault && <UserCircle size={18} />)}
      </div>
    );
  }
);

UserAvatar.displayName = "UserAvatar";

export default UserAvatar;
