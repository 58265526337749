import { AxiosRequestConfig } from "axios";
import apiInstance from "holocene-services/axios";

import { authHeader } from "../auth-header";
import {
  TMimeType,
  convertFileToBlob,
  convertFileToPdfBlob,
  isDeepEqual,
} from "holocene-utils/helpers";
import {
  DocumentClassificationItem,
  IDestinationCountryFilter,
  IDispatchTrackingEvent,
  IGetConsolidateShipmentsRequest,
  IGetShipmentsRequest,
  IGetShipmentsResponse,
  IGetShipmentsWithStatusRequest,
  IGetShipmentsWithStatusResponse,
  IOriginCountryFilter,
  IPostConsolidateShipmentRequest,
  IRecommendationItem,
  IShipment,
  IShipmentAlert,
  IShipmentDocument,
  IShipmentDocumentUpdate,
  IShipmentEntry,
  IShipmentIds,
  IShipmentOverallDispatch,
  IShipmentProgress,
  IShipmentStatusRow,
  IShipmentTableData,
  IShipmentTableRow,
  LoadPlanCalculationResponse,
  LoadPlanItem,
  OrderType,
  ShipmentDocumentType,
  ShipmentInstructions,
  ShipmentStatus,
  ShipmentStep,
  ShipmentSubstep,
} from "./types";
import { WebViewerInstance } from "@pdftron/webviewer";
import { CustomerDocumentTemplate } from "holocene-services/settings.service/types";
import { MIME_TYPE } from "holocene-constants/documents.constants";

interface ConsolidatedShipmentUploadResponse {
  documentType: string;
  documentTypeId: number;
  location: string;
  isCommonDoc?: boolean;
  userShipmentStepId?: number;
}

export const packingListVersions = ["Packing List", "Packing_List", "packing list", "packing_list"];

export const uploadFile = async (
  file: File,
  shipmentId: number,
  webViewerInstance: WebViewerInstance,
  isConsolidated?: boolean
): Promise<DocumentClassificationItem> => {
  const defaultError = `Failed to upload a document - ${file.name}: 
  `;
  const fileTypes = [MIME_TYPE.pdf, MIME_TYPE.xlsx];
  if (!fileTypes.includes(file.type)) {
    return { error: defaultError + "Only PDF files can be uploaded." };
  }
  let formData = new FormData();

  // We only add the shipment ID for non consolidated shipments
  if (!isConsolidated) formData.append("shipmentId", shipmentId?.toString() || "");

  formData.append("file", file);

  try {
    const response = await apiInstance
      .post(
        isConsolidated
          ? "/consolidate-shipments/bundle-docs?consolidatedOrderId=" + shipmentId
          : `/shipment-documents/bundle-docs`,
        formData
      )
      .then((r) => r.data as IShipmentDocument | ConsolidatedShipmentUploadResponse);
    const docType = isConsolidated
      ? (response as ConsolidatedShipmentUploadResponse).documentType
      : (response as IShipmentDocument).extractedValues?.doc_type.toLowerCase();
    const isOther = ["other", "others"].includes(docType);
    let thumbnail = "";
    if (webViewerInstance) {
      const doc = await webViewerInstance.Core.createDocument(file, {
        extension: "pdf",
      });
      thumbnail = await new Promise((resolve) => {
        doc.loadThumbnail(1, (thumb: any) => {
          resolve(thumb.toDataURL());
        });
      });
    }

    const isPackingList = packingListVersions.includes(docType);
    return {
      documentTypeId: response.documentTypeId,
      documentType: docType,
      docId: (response as IShipmentDocument).id,
      needsClassification: isOther || isConsolidated || !response.userShipmentStepId,
      file: file,
      location: response.location,
      isCommonDoc: response?.isCommonDoc || false,
      isPackingList,
      thumbnail,
    };
  } catch (err: any) {
    return { error: err.response?.data?.message || defaultError };
  }
};
class ShipmentService {
  getShipmentsRequestController: AbortController | undefined;
  prevGetShipmentRequest: IGetShipmentsRequest | undefined;
  getShipmentsWithStatusRequestController: AbortController | undefined;
  prevGetShipmentsWithStatusRequest: IGetShipmentsWithStatusRequest | undefined;

  async getShipments(requestData: IGetShipmentsRequest): Promise<IShipmentTableData> {
    if (
      this.getShipmentsRequestController &&
      isDeepEqual(this.prevGetShipmentRequest, requestData)
    ) {
      this.getShipmentsRequestController.abort();
    }
    this.prevGetShipmentRequest = requestData;
    this.getShipmentsRequestController = new AbortController();

    const response = await apiInstance.get<IGetShipmentsResponse>(`/shipments/consolidated`, {
      params: {
        skip: requestData.skip,
        take: requestData.take,
        groupByCountry: requestData.groupByCountry,
        shipmentStatus: requestData.shipmentStatus,
        isMyShipments: requestData.isMyShipments,
        originCountryIds: requestData.originCountryIds,
        destinationCountryIds: requestData.destinationCountryIds,
        transportIds: requestData.transportIds,
        customerIds: requestData.customerIds,
        operationType: requestData.operationType,
      },
    });
    return response.data;
  }
  async getShipments2(requestData: IGetShipmentsRequest): Promise<IShipmentTableData> {
    if (
      this.getShipmentsRequestController &&
      isDeepEqual(this.prevGetShipmentRequest, requestData)
    ) {
      this.getShipmentsRequestController.abort();
    }
    this.prevGetShipmentRequest = requestData;
    this.getShipmentsRequestController = new AbortController();

    const response = await apiInstance.get<IGetShipmentsResponse>(`/shipments/group-by`, {
      params: {
        skip: requestData.skip,
        take: requestData.take,
        groupByCountry: requestData.groupByCountry,
        shipmentStatus: requestData.shipmentStatus,
        isMyShipments: requestData.isMyShipments,
        originCountryIds: requestData.originCountryIds,
        destinationCountryIds: requestData.destinationCountryIds,
        transportIds: requestData.transportIds,
        customerIds: requestData.customerIds,
        operationType: requestData.operationType,
      },
    });
    return response.data;
  }

  async updateShipments(updatedData: Partial<IShipment>, shipmentId: number) {
    const response = await apiInstance.patch(`/shipments/${shipmentId}`, updatedData);
    return response?.data ? response.data : response;
  }

  async updateShipmentDocuments(documentData: IShipmentDocumentUpdate, isConsolidated?: boolean) {
    const response = await (isConsolidated
      ? apiInstance.post("/consolidate-shipments/consolidated-shipment-document", documentData)
      : apiInstance.patch(`/shipment-documents/${documentData.documentId}`, documentData));
    return response?.data ? response.data : response;
  }

  async loadShipmentInfo(shipmentId: number) {
    const response = await apiInstance.get(`/shipments/${shipmentId}`);

    return response?.data;
  }

  async shipmentAlerts(shipmentId: number) {
    const response = await apiInstance.get<IShipmentAlert[]>(`/shipments/${shipmentId}/alerts`);
    return response.data.filter(({ isRead }) => !isRead);
  }

  async markShipmentAlertAsRead(alertId: number) {
    const response = await apiInstance.patch(`/shipment-alerts/${alertId}/read`, null);
    return response.data;
  }

  async markPartial(shipmentId: number, isPartial?: boolean) {
    const response = await apiInstance.post("/shipments/delivery-quantity", {
      shipmentId,
      isPartial,
    });
    return response.data;
  }

  async recommendations(shipmentId: number) {
    const response = await apiInstance.get<IRecommendationItem[]>(
      `/shipments/${shipmentId}/recommendation`
    );
    return response.data;
  }

  async getConsolidatedShipmentRecommendations(shipmentId: number) {
    return apiInstance
      .get(`/consolidate-shipments/${shipmentId}/recommendation`)
      .then((res) => res.data);
  }

  async deleteShipment(shipmentId: number) {
    const response = await apiInstance.delete(`/shipments/${shipmentId}`);
    return response.data;
  }

  async uploadFiles({
    files,
    shipmentId,
    webViewerInstance,
    isConsolidated,
  }: {
    files: File[];
    shipmentId: number;
    webViewerInstance?: any;
    isConsolidated?: boolean;
  }) {
    const response = [];
    for (let i = 0; i < files.length; i++) {
      response.push(await uploadFile(files[i], shipmentId, webViewerInstance, isConsolidated));
    }
    return response;
  }

  async deleteDocument(documentId: number) {
    const response = await apiInstance.delete(`/shipment-documents/${documentId}`);
    return response.data;
  }

  async deleteConsolidatedDocument(s3DocumentLocation: string) {
    return apiInstance.delete("/consolidate-shipments/consolidated-shipment-document", {
      params: { s3DocumentLocation },
    });
  }

  async updateShipmentDocumentsFile({
    id,
    file,
    shipmentId,
    documentTypeId,
    draft,
  }: {
    id: string | number;
    file: Blob;
    shipmentId: number;
    documentTypeId: number;
    draft: boolean;
  }) {
    const response = await apiInstance.post(
      `/shipment-documents/${id}`,
      {
        file,
        shipmentId,
        documentTypeId,
        draft,
      },
      { headers: authHeader("multipart/form-data") }
    );
    return response.data;
  }

  async getShipmentDocuments(skip: number, take: number, shipmentId: number) {
    const response = await apiInstance.get(`/shipment-documents`, {
      params: {
        skip,
        take,
        shipmentId,
      },
    });
    return response.data;
  }

  async postShipmentDocumentType({
    shipmentId,
    documentName,
  }: {
    shipmentId: number;
    documentName: string;
  }) {
    return apiInstance.post("/user-shipment-steps", {
      shipmentId,
      substepName: documentName,
      tradeLaneMetadata: JSON.stringify({ name: documentName, value: true, widget: "checkbox" }),
      shipmentMetadata: JSON.stringify({}),
    });
  }

  async updateShipmentStep(id: number, payload: Partial<ShipmentStep> | Partial<ShipmentSubstep>) {
    return apiInstance.patch(`/user-shipment-steps/${id}`, payload);
  }

  async getShipmentDocumentsFile(s3Uri: string, token?: string) {
    const config: AxiosRequestConfig = {
      params: {
        s3Uri,
      },
    };
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }
    const response = await apiInstance.get(`/shipment-documents/file`, config);
    return response?.data || { data: [] };
  }

  getShipmentFileBlob = (file: IShipmentDocument) =>
    this.getShipmentDocumentsFile(file.location).then((res) => ({
      ...file,
      fileBlob: convertFileToPdfBlob(res.data),
    }));

  getFileBlob = (file: CustomerDocumentTemplate) => {
    const ext = file.location.split(/[#?]/)[0]?.split(".")?.pop()?.trim() || "pdf";
    return this.getShipmentDocumentsFile(file.location).then((res) => ({
      ...(file as any),
      fileBlob: convertFileToBlob(res.data, `.${ext}` as TMimeType),
    }));
  };

  async getDocumentTypes(
    originCountryId: number,
    destCountryId: number,
    groupBy?: boolean
  ): Promise<ShipmentDocumentType[]> {
    const response = await apiInstance.get(`/document-types`, {
      params: {
        originCountryId,
        destCountryId,
        groupBy,
      },
    });
    return (response?.data ?? []) as ShipmentDocumentType[];
  }

  async getShipmentsWithStatus(
    requestData: IGetShipmentsWithStatusRequest
  ): Promise<IGetShipmentsWithStatusResponse> {
    if (
      this.getShipmentsWithStatusRequestController &&
      isDeepEqual(this.prevGetShipmentsWithStatusRequest, requestData)
    ) {
      this.getShipmentsWithStatusRequestController.abort();
    }
    this.prevGetShipmentsWithStatusRequest = requestData;
    this.getShipmentsWithStatusRequestController = new AbortController();

    const response = await apiInstance.get(`/shipments`, {
      params: requestData,
    });
    return response.data;
  }
  async getConsolidatedShipmentsWithStatus(
    requestData: IGetShipmentsWithStatusRequest
  ): Promise<IGetShipmentsWithStatusResponse> {
    const response = await apiInstance.get(`/shipments/consolidated`, {
      params: requestData,
    });
    return response.data;
  }

  async getShipmentCount() {
    const response = await apiInstance.get(`/shipments/count`);
    return response.data;
  }

  async getShipmentIds(shipmentStatus?: string) {
    const response = await apiInstance.get<IShipmentIds[]>(`/shipments/shipment-unique-ids`, {
      ...(shipmentStatus && {
        params: {
          shipmentStatus,
        },
      }),
    });
    return response.data;
  }

  async addCourierTracking(requestData: {
    shipmentId: number;
    shipmentTracking: string;
    shipmentType: string;
  }) {
    const response = await apiInstance.post<IShipmentIds[]>(`/shipments-tracking`, requestData);
    return response.data;
  }

  async dispatchShipmentWihoutTracking(shipmentId: number) {
    const response = await apiInstance.post<IShipmentIds[]>(`/dispatches`, { shipmentId });
    return response.data;
  }

  async export(shipmentStatus?: ShipmentStatus) {
    const response = await apiInstance.post(
      `/shipments/export${shipmentStatus ? `?shipmentStatus=${shipmentStatus}` : ""}`,
      {
        responseType: "blob",
      }
    );
    return response.data;
  }

  async addShipmentTracking({ shipmentId, tracking }: { shipmentId: number; tracking: string }) {
    return apiInstance.post("/dispatches/bulk-create", {
      shipmentId,
      createdShipmentTrackings: [tracking],
      deletedShipmentTrackings: [],
    });
  }

  async deleteShipmentTracking({ shipmentId, tracking }: { shipmentId: number; tracking: string }) {
    return apiInstance.post("/dispatches/bulk-create", {
      shipmentId,
      deletedShipmentTrackings: [tracking],
      createdShipmentTrackings: [],
    });
  }

  async getLoadPlanShipment(shipmentId: number) {
    return apiInstance
      .get("/shipment-load-plan-items", { params: { shipmentId } })
      .then((res) => res.data) as Promise<LoadPlanItem[]>;
  }

  async getLoadPlanConsolidated(consolidatedOrderId: number) {
    return apiInstance
      .get("/shipment-load-plan-items/consolidation", {
        params: { consolidatedOrderId },
      })
      .then((res) => res.data) as Promise<LoadPlanItem[]>;
  }

  async getLoadPlan(id: number, type: OrderType) {
    const agnosticFn =
      type === OrderType.consolidation ? this.getLoadPlanConsolidated : this.getLoadPlanShipment;

    return agnosticFn(id);
  }

  async getLoadPlanCalculationShipment(shipmentId: number) {
    return apiInstance
      .get("/shipment-load-plan-items/calculate", { params: { shipmentId } })
      .then((res) => {
        if (res.data.status !== "success") throw new Error();
        return res.data;
      }) as Promise<LoadPlanCalculationResponse>;
  }

  async getLoadPlanCalculationCons(consolidatedOrderId: number) {
    const url = "/shipment-load-plan-items/consolidation/calculate";
    const params = { params: { consolidatedOrderId } };
    return apiInstance.get(url, params).then((res) => {
      if (res.data.status !== "success") throw new Error();
      return res.data;
    }) as Promise<LoadPlanCalculationResponse>;
  }

  async getLoadPlanCalculation(id: number, type: OrderType) {
    const agnosticFn =
      type === OrderType.consolidation
        ? this.getLoadPlanCalculationCons
        : this.getLoadPlanCalculationShipment;

    return agnosticFn(id);
  }

  async getLoadPlanItem({ shipmentId, itemId }: { shipmentId: number; itemId: number }) {
    return apiInstance.get(`/shipment-load-plan-items/${itemId}`, { params: { shipmentId } });
  }

  async postLoadPlanItem({ shipmentId, payload }: { shipmentId: number; payload: LoadPlanItem }) {
    return apiInstance
      .post(`/shipment-load-plan-items?shipmentId=${shipmentId}`, [payload])
      .then((res) => res.data);
  }

  async patchLoadPlanItem({
    shipmentId,
    itemId,
    payload,
  }: {
    shipmentId: number;
    itemId: number;
    payload: Partial<LoadPlanItem>;
  }) {
    return apiInstance.patch(
      `shipment-load-plan-items/${itemId}?shipmentId=${shipmentId}`,
      payload
    );
  }

  async deleteLoadPlan(shipmentId: number, loadPlanId: number) {
    const response = await apiInstance.delete(
      `/shipment-load-plan-items?shipmentId=${shipmentId}&loadPlanIds=${loadPlanId}`
    );
    return response.data;
  }
  async updateLoadPlan(updatedData: Partial<LoadPlanItem>, shipmentId: number) {
    const response = await apiInstance.patch(
      `/shipment-load-plan-items/${updatedData.id}?shipmentId=${shipmentId}`,
      updatedData
    );
    return response?.data ? response.data : response;
  }

  getShipmentInstructions(shipmentId: number) {
    return apiInstance
      .get(`/shipments/${shipmentId}/shipment-instructions`)
      .then((res) => res.data) as Promise<ShipmentInstructions>;
  }

  uploadNewShipment(documentType: string, formData: any) {
    return apiInstance.post(`/shipments/upload?documentType=${documentType}`, formData);
  }

  async getConsolidateShipments(params: IGetConsolidateShipmentsRequest): Promise<any | undefined> {
    if (
      !params.originCountryId ||
      !params.destinationCountryId ||
      !params.modeOfTransportId ||
      !params.shippingOperationType
    )
      return undefined;
    return apiInstance.get("/consolidate-shipments", { params }).then((res) => res);
  }

  postConsolidateShipments(params: IPostConsolidateShipmentRequest) {
    return apiInstance.post(`/consolidate-shipments`, {
      ...params,
    });
  }
  async unlinkConsolidatedShipments(shipmentIds: number[]) {
    const response = await apiInstance.delete("/consolidate-shipments", {
      data: { shipments: shipmentIds || undefined },
    });

    return response.data;
  }
  async loadConsolidatedShipmentInfo(id: number) {
    const response = await apiInstance.get(`/consolidate-shipments/${id}`);
    return response?.data;
  }
  async updateConsolidatedShipments(updatedData: Partial<IShipment>, shipmentId: number) {
    const response = await apiInstance.patch(`/consolidate-shipments/${shipmentId}`, updatedData);
    return response?.data ? response.data : response;
  }
  async deleteDocumentByLocation(location: string) {
    const response = await apiInstance.delete(
      `/consolidate-shipments/consolidated-shipment-document?s3DocumentLocation=${location}`
    );
    return response.data;
  }

  async searchShipments(search: string, pageParam: { skip: number; take: number }) {
    return apiInstance
      .get(`/shipments/search`, { params: { search, ...pageParam } })
      .then((res) => res.data);
  }
}

export default new ShipmentService();

export { ShipmentStatus };

export type {
  IDestinationCountryFilter,
  IDispatchTrackingEvent,
  IGetShipmentsRequest,
  IOriginCountryFilter,
  IRecommendationItem,
  IShipment,
  IShipmentAlert,
  IShipmentDocument,
  IShipmentEntry,
  IShipmentIds,
  IShipmentOverallDispatch,
  IShipmentProgress,
  IShipmentStatusRow,
  IShipmentTableData,
  IShipmentTableRow,
};
