import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import OutsideClickHandler from "react-outside-click-handler";
import { useState } from "react";
import Input, { InputWithIcon } from "../new/Input";
import ReactTooltip from "react-tooltip";
import { useGlobalSearch } from "holocene-providers/common/GlobalSearch";
import { useRouter } from "next/router";
import { routes } from "holocene-constants/routes";
import { MagnifyingGlass } from "phosphor-react";

const SearchInput = () => {
  const { setSearchText } = useGlobalSearch();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const router = useRouter();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (search) {
          setSearchText(search);
        }
        router.push(routes.search);
      }}
    >
      <InputWithIcon
        placeholder="Search Orders"
        name="searchString"
        className="w-52"
        inputClassName="!h-[30px] !text-xs"
        icon={<MagnifyingGlass size={16} weight="bold" className="text-holocene-blue" />}
        onChange={(e) => setSearch(e.target.value)}
        placeholderClass="placeholder-holocene-blue"
      />
    </form>
  );
};
export default SearchInput;
