import { modeOfTransportDDStyles } from "holocene-components/common/new/ModeOfTransportDD/constants";
import Select, { components, SingleValueProps, StylesConfig } from "react-select";
import {
  TransportIcon,
  TransportModeOption,
  TransportModeOptionV2,
} from "holocene-components/common/new/TransportIcon";
import { CaretDown } from "phosphor-react";
import { useGetModesOfTransportation } from "holocene-hooks/logistics.hooks";
import { useMemo } from "react";

const { Option, SingleValue } = components;

const IconOption = (props: any) => (
  <Option {...props} isDisabled={!props.data.enabled}>
    <TransportIcon mode={props.data.label} />
  </Option>
);

const Value = ({ children, ...props }: SingleValueProps<any>) => (
  <SingleValue {...props}>
    <TransportIcon mode={props.data.label} />
  </SingleValue>
);

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown size={16} className="text-holocene-blue" />
    </components.DropdownIndicator>
  );
};

export const ModeOfTransportSelect = ({
  required,
  value,
  disabled,
  onChange,
  styles,
}: {
  required?: boolean;
  value?: TransportModeOption | TransportModeOptionV2;
  disabled?: boolean;
  onChange: (value: TransportModeOption | TransportModeOptionV2) => void;
  styles?: StylesConfig;
}) => {
  const { data } = useGetModesOfTransportation();
  const options = useMemo(() => data?.map(({ id, type }) => ({ label: type, value: id })), [data]);
  return (
    <div>
      <label className="block text-xs text-secondary-text font-semibold mb-1 whitespace-nowrap">
        Mode of Transport{required && "*"}
      </label>
      <Select
        styles={{ ...modeOfTransportDDStyles, ...styles } as StylesConfig}
        className={"basic-single "}
        classNamePrefix="select"
        options={options}
        components={{
          Option: IconOption,
          SingleValue: Value,
          DropdownIndicator: DropdownIndicator,
        }}
        hideSelectedOptions={false}
        isClearable={false}
        isSearchable={false}
        placeholder="Select"
        value={value}
        onChange={(opt) => onChange(opt as TransportModeOption)}
        isDisabled={disabled}
      />
    </div>
  );
};

export const ModeOfTransportMultiSelect = ({
  required,
  value,
  disabled,
  onChange,
  label = "Mode of Transport",
  styles,
}: {
  required?: boolean;
  value?: TransportModeOptionV2[];
  disabled?: boolean;
  onChange: (value: TransportModeOptionV2[]) => void;
  label?: string;
  styles?: StylesConfig;
}) => {
  const { data } = useGetModesOfTransportation();
  const options = useMemo(() => data?.map(({ id, type }) => ({ label: type, value: id })), [data]);
  return (
    <div>
      <label className="block text-xs text-secondary-text font-semibold mb-1 whitespace-nowrap">
        {label}
        {required && "*"}
      </label>
      <Select
        className={"basic-single "}
        classNamePrefix="select"
        styles={{ ...modeOfTransportDDStyles, ...styles } as StylesConfig}
        options={options}
        isMulti
        components={{
          Option: IconOption,
          MultiValue: Value,
          DropdownIndicator: DropdownIndicator,
        }}
        hideSelectedOptions={false}
        isClearable={false}
        isSearchable={false}
        placeholder="Select"
        value={value}
        onChange={(opts) => onChange(opts as TransportModeOptionV2[])}
        isDisabled={disabled}
        closeMenuOnSelect={false}
      />
    </div>
  );
};
