import { IShipmentDocument, IShipment } from "holocene-services/shipment.service/types";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { WebViewerInstance } from "@pdftron/webviewer";

const ShipmentDocumentContext = createContext<{
  shipment: null | IShipment;
  selectedDocument: null | IShipmentDocument;
  setSelectedDocument: (doc: null | IShipmentDocument) => void;
  loading: boolean;
  resetContext: () => void;
  triggerLoading: () => void;
  shouldTriggerEmail: boolean;
  setShouldTriggerEmail: (val: boolean) => void;
  loadPlanDisabled: boolean;
  setLoadPlanDisabled: (val: boolean) => void;
  setPDFInstance: (instance: WebViewerInstance) => void;
  pdfViewerInstance: WebViewerInstance | null;
  setGenerateTemplateData: (data: any) => void;
  invoiceGenerateData: any;
  setConsolidatedShipments: (docs: any[]) => void;
  consolidatedShipments: any[];
  setReclassifyDocs: (docs: IShipmentDocument[]) => void;
  reclassifyDocs: any[];
}>({
  shipment: null,
  selectedDocument: null,
  setSelectedDocument: () => {},
  loading: false,
  resetContext: () => {},
  triggerLoading: () => {},
  shouldTriggerEmail: false,
  setShouldTriggerEmail: () => {},
  loadPlanDisabled: false,
  setLoadPlanDisabled: () => {},
  setPDFInstance: () => {},
  pdfViewerInstance: null,
  setGenerateTemplateData: () => {},
  invoiceGenerateData: {},
  setConsolidatedShipments: () => {},
  consolidatedShipments: [],
  setReclassifyDocs: () => {},
  reclassifyDocs: [],
});

export const ShipmentDocumentContextProvider: React.FC<{
  shipment: IShipment | null;
}> = ({ shipment, children }) => {
  const [selectedDocument, setSelectedDocument] = useState<IShipmentDocument | null>(null);
  const [shouldTriggerEmail, setShouldTriggerEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [loadPlanDisabled, setLoadPlanDisabled] = useState(false);
  const [pdfViewerInstance, setPdfViewerInstance] = useState<WebViewerInstance | null>(null);
  const [invoiceGenerateData, setInvoiceGenerateData] = useState(undefined);
  const [consolidatedShipments, setShipments] = useState<any[]>([]);
  const [reclassifyDocs, setReclassify_Docs] = useState([]);
  const resetContext = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(false);
  }, []);

  const setGenerateTemplateData = (data: any) => {
    setInvoiceGenerateData(data);
  };

  const triggerLoading = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(true);
    timeoutRef.current = setTimeout(resetContext, 30000);
  };
  const setPDFInstance = (webViewerInstance: WebViewerInstance) => {
    setPdfViewerInstance(webViewerInstance);
  };
  const setConsolidatedShipments = (docs: any[]) => {
    setShipments(docs);
  };
  const setReclassifyDocs = (docs: any) => {
    setReclassify_Docs(docs);
  };

  return (
    <ShipmentDocumentContext.Provider
      value={{
        shipment,
        selectedDocument,
        setSelectedDocument,
        loading,
        triggerLoading,
        resetContext,
        shouldTriggerEmail,
        setShouldTriggerEmail,
        loadPlanDisabled,
        setLoadPlanDisabled,
        setPDFInstance,
        pdfViewerInstance,
        setGenerateTemplateData,
        invoiceGenerateData,
        setConsolidatedShipments,
        consolidatedShipments,
        setReclassifyDocs,
        reclassifyDocs,
      }}
    >
      {children}
    </ShipmentDocumentContext.Provider>
  );
};

export const useShipmentDocumentContext = () => {
  return useContext(ShipmentDocumentContext);
};
