import axios from "axios";
import { routes } from "holocene-constants/routes";
import { authHeader } from "./auth-header";
import { operationModuleHeader } from "./operation-module-header";
import { clearLocalStorage } from "holocene-utils/common.utils";

const apiInstance = axios.create({
  baseURL: `${process.env.Base_URL}/api/v1`,
});

apiInstance.interceptors.request.use((req) => {
  req.headers = { ...authHeader(), ...operationModuleHeader(), ...req.headers };
  return req;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      clearLocalStorage();
      window.location.href = window.location.origin + routes.logout;
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
