import { ShipmentType, TransportModeName } from "holocene-components/common/new/TransportIcon";
import { MappedAvailabletDocuments } from "holocene-components/shipment-queue/ShipmentSteps/common.types";
import { TradeLaneStepMetadata } from "holocene-providers/tradeLanes/TradeLaneFormContext";
import { IPrivateTradeLaneNote } from "holocene-services/tradeLanes.service";
import { ICountry } from "holocene-services/user.service";

export enum ShipmentStatus {
  pending = "pending",
  dispatched = "dispatched",
  delayed = "delayed",
  complete = "complete",
}
export interface IGetShipmentsRequest {
  skip: number;
  take: number;
  groupByCountry?: boolean;
  shipmentStatus?: ShipmentStatus;
  isMyShipments?: boolean;
  originCountryIds?: number[];
  destinationCountryIds?: number[];
  transportIds?: number[];
  customerIds?: string;
  operationType?: string;
}

export interface IGetShipmentsWithStatusRequest {
  skip: number;
  take: number;
  groupByCountry?: boolean;
  shipmentStatus?: ShipmentStatus;
  originCountryIds?: string;
  destinationCountryIds?: string;
  transportIds?: string;
  incotermIds?: string;
  isMyShipments?: boolean;
  selectedUserIds?: string;
  showExceptions?: boolean;
  customerIds?: string; //comma separated ids
  operationType?: "sales" | "purchase";
}

export interface IDestinationCountryFilter {
  shipmentDestinationCountry: {
    id: number;
    alpha2Code: string;
    officialName: string;
  };
}

export interface IOriginCountryFilter {
  shipmentOriginCountry: {
    id: number;
    alpha2Code: string;
    officialName: string;
  };
}

export interface IModeOfTransportFilter {
  id: number;
  name: TransportModeName;
}

export interface IGetShipmentsResponse {
  shipments: {
    [key: string]: { shipments: IShipmentEntry[]; consolidatedShipments: IConsolidatedShipment[] };
  };
  count: number;
  skip: number;
  take: number;
  destinationCountries: IDestinationCountryFilter[];
  originCountries: IOriginCountryFilter[];
  modeOfTransports: IModeOfTransportFilter[];
}

export interface IGetShipmentsWithStatusResponse {
  count: number;
  destinationCountries: IDestinationCountryFilter[];
  originCountries: IOriginCountryFilter[];
  modeOfTransports: IModeOfTransportFilter[];
  lastUpdatedAt: string;
  incoterms: Incoterm[];
  shipments: ShipmentWithStatus[];
}

export interface IOverallDispatch {
  id: number;
  uniqueIdentifier: string;
  dateOfDispatch: string;
  shipmentTracking: string;
  shipmentType: string;
  trackingEvent: string;
  dateOfEvent: string;
  etaOld: string;
  etaNew: string;
  changeInEta: true;
  ddIndicator: null;
  ddCost: null;
  carrierName: string;
  carrierTrackingUrl: string;
  lastUpdated: string;
  destination: string;
  shipmentId: number;
  customerId: number;
  containerIsoCode: string;
  prePolLocode: null;
  prePolTransportMode: null;
  prePolCityName: null;
  prePolCountryCode: null;
  polCountryCode: string;
  polCityName: string;
  polLocode: string;
  podCountryCode: string;
  podCityName: string;
  podLocode: null;
  postPodLocode: string;
  postPodTransportMode: string;
  postPodCityName: string;
  postPodCountryCode: string;
  trackingStatus: string;
  lat: string;
  lng: string;
  route?: string;
  modesOfTransport: TransportModeName;
  polCountryName: string;
  podCountryName: string;
  carbonEmission: string;
}

export type ShipmentListingWithStatus = ShipmentWithStatus | ConsolidatedShipmentWithStatus;

export interface ConsolidatedShipmentWithStatus {
  id: number;
  name: string;
  originCountryId: number;
  destinationCountryId: number;
  transportModeId: number;
  createdAt: string;
  status: ShipmentStatus;
  updatedAt: string;
  createdById: number;
  updatedById: number;
  shipments: ShipmentWithStatus[];
  overallDispatch: IOverallDispatch;
  isConsolidated: true;
  tag?: string;
  clientName?: string;
  predictedETA?: string;
  portDetail?: {
    averageDelay: string;
  };
  containerCount?: number;
  transportMode?: { id: number; name: TransportModeName };
}

export interface ShipmentWithStatus {
  id: number;
  incomingOrderId: null;
  uniqueIdentifierType: string;
  incomingOrder: {
    uniqueIdentifier: string;
    deliveryQuantity?: string | null;
  };
  shipmentOwner: number;
  incotermId: number;
  originCountryId: number;
  destinationCountryId: number;
  createdAt: string;
  tradeLaneId: number;
  lastUpdatedTs: string;
  lastAction: string;
  nextAction: string;
  etdGoal: null;
  status: string;
  customerId: number;
  overallDDIndicator?: string;
  overallDDCost?: number;
  isConsolidated: false;
  shipmentDestinationCountry: {
    id: number;
    alpha2Code: string;
    alpha3Code: string;
    officialName: string;
    avgClearingTimeInDays: string;
    stGallenCountryName: string;
  };
  shipmentOriginCountry: {
    id: number;
    alpha2Code: string;
    alpha3Code: string;
    officialName: string;
    avgClearingTimeInDays: string;
    stGallenCountryName: string;
  };
  incoterm: Incoterm;
  etaNew: string;
  etaOld: string;
  changeInEta: boolean | null;
  trackingEvent: string;
  carrierName: string;
  overallDispatch: IOverallDispatch;
  tag?: string;
  clientName?: string;
  containerCount?: number;
  transportMode: { id: number; name: TransportModeName };
  predictedETA?: string;
  scheduleDays?: string;
  portDetail?: {
    averageDelay: string;
  };
  shipments?: ShipmentWithStatus[];
}

export interface IDispatch {
  carrierName: string;
  changeInEta: boolean | null;
  containerIsoCode: string;
  customerId: number;
  dateOfDispatch: string;
  dateOfEvent: string;
  ddCost: number | null;
  ddIndicator: string | null;
  destination: string;
  etaNew: string;
  etaOld: string;
  id: number;
  lastUpdated: string;
  lat: string;
  lng: string;
  podCountryCode: string;
  podLocode: string;
  polCountryCode: string;
  polLocode: string;
  shipmentId: number;
  shipmentTracking: string;
  shipmentType: string;
  trackingEvent: string;
  trackingStatus: string;
  uniqueIdentifier: string;
  demurrageFines: {
    exportFreeTime: number | null;
    exportFreeTimeLapsed: number | null;
    importFreeTime: number | null;
    importFreeTimeLapsed: number | null;
  }[];
  detentionFines: {
    exportFreeTime: number | null;
    exportFreeTimeLapsed: number | null;
    importFreeTime: number | null;
    importFreeTimeLapsed: number | null;
  }[];
}

export interface IDispatchTrackingEvent {
  id: number;
  port: string;
  portCode: string;
  event: string;
  eventDate: string;
}

interface Incoterm {
  id: number;
  name: string;
  code: string;
  applicableTo: string;
}
export interface IAvailableDocs {
  docTypes: [
    {
      categorized: boolean;
      countryType: null;
      extracted_type: string;
      groupName: string;
      id: number;
      named: boolean;
      order: number;
      type: string;
      userFriendlyName: string;
    }
  ];
  userShipmentStepId: number;
}
export interface IShipment {
  projectId?: number | null;
  customerId: number;
  destination: string;
  port?: string;
  id: number;
  availableDocTypes: IAvailableDocs[];
  incomingOrder: {
    uniqueIdentifier: string;
    deliveryQuantity?: string | null;
  };
  uniqueIdentifier?: string;
  uniqueIdentifierType: string;
  alertCounter: number;
  recommendationCounter: number;
  incoTerm: number;
  createdAt: string;
  tradeLaneId: number;
  lastUpdatedTs: string;
  lastUpdatedAt: string;
  status: string;
  etdGoal?: string;
  etaOld: string;
  etaNew: string;
  changeInEta: boolean;
  lastAction?: string;
  nextAction?: string;
  count: number;
  shipmentOwner: number;
  shipmentDocuments: IShipmentDocument[];
  shipmentMissingDocuments?: IShipmentMissingDocuments[];
  shipmentDocumentAlerts?: IShipmentAlert[];
  shipmentDestinationCountry: ICountry;
  shipmentOriginCountry: ICountry;
  shipmentLoadPlanItems: LoadPlanItem[];
  transportMode: {
    name: TransportModeName;
    id: number;
  };
  carrierName?: string;
  docCutOffDate: string | null;
  pickUpDate: string | null;
  tradeLane: {
    id: number;
    operationType: "purchase" | "sales";
    keyContact: string;
    tradeLaneNotes?: IPrivateTradeLaneNote[];
  };
  incoterm: Incoterm;
  modeOfTransports: TransportModeName[];
  customer: {
    name: string;
    shortName: string;
    address: string;
  };
  user: {
    email: string;
    name: string;
    profileUrl: string;
  };
  dispatches: IDispatch[];
  freeTimeUsed: {
    portInfo: {
      portName: string;
      demurrageImportFreeTime?: number;
      demurrageImportFreeTimeLapsed?: number;
      detentionImportFreeTime?: number;
      detentionImportFreeTimeLapsed?: number;
    };
  }[];
  overallDDCost?: number;
  overallDDIndicator?: string;
  overallDispatch: IShipmentOverallDispatch;
  userShipmentSteps: ShipmentStep[];
  transportModeName?: TransportModeName;
  averageDelay?: number;
  consolidatedOrderShipments: {
    consolidatedOrderId: number;
    createdAt: string;
    createdById: number;
    id: number;
    shipment: IShipment;
    shipmentId: number;
  }[];
  userConsolidatedOrderSteps: ShipmentStep[];
  availableDocTypesConsolidated: { shipmentId: number; availableDocTypes: IAvailableDocs[] }[];
  docTypeShipmentMap: any;
  name?: string;
  updatedAt?: string;
  availableDocsByStepsId?: MappedAvailabletDocuments;
}

export interface IShipmentOverallDispatch {
  polCityName: string;
  podCityName: string;
  dispatchTrackingEvents: IDispatchTrackingEvent[];
  modesOfTransport: TransportModeName;
  shipmentType: ShipmentType;
  prePolTransportMode: TransportModeName;
  prePolCityName: string;
  prePolCountryCode: string;
  prePolLocode: string;
  polCountryName: string;
  polLocode: string;
  podCountryName: string;
  podLocode: string;
  postPodTransportMode: TransportModeName;
  postPodCityName: string;
  postPodCountryCode: string;
  postPodLocode: string;
  carbonEmission: string;
  pieces: number;
}

export interface IShipmentDocument {
  id: number;
  shipmentId: number;
  documentId: number;
  documentTypeId: number;
  document: {
    countryDocTypeRef: any;
    documentType: any;
    documentTypeId: number;
    name: string;
    id: number;
  };
  uploaded: boolean;
  uploadTs: Date;
  draft: boolean;
  legalised: boolean;
  certified: boolean;
  extractedValues: {
    doc_type: string;
    entities: any;
  };
  type: string;
  reclassified: any; // TODO: add type
  location: string;
  fileBlob?: any;
  userShipmentStepId: number;
  userFriendlyName: string;
  documents?: IShipmentDocument[];
  documentType?: {
    type: string;
  };
  isCommonDoc?: boolean;
  documentExtractedType?: string;
  isMasterBoL?: boolean; // Indicates whether the doc is master bill of lading - only applicable for consolidated shipments with mode === sea
}

export interface IShipmentMissingDocuments {
  shipmentId: number;
  missingDocTypes: {
    categorized: boolean;
    countryType: number | null;
    extracted_type: string;
    groupName: string;
    id: number;
    named: boolean;
    order: number;
    type: string;
    userFriendlyName: string;
    userShipmentStepId: number;
  }[];
}

export interface shipmentUpdatedData {
  etdGoal?: Date;
  lastAction?: string;
  nextAction?: string;
  status?: string;
  documentId?: number;
  shipmentOwner?: number;
  userShipmentSteps?: ShipmentStep[];
}

export interface IShipmentProgress {
  documentName?: string;
  uploaded?: boolean;
  uploadedAt?: string;
  groupName?: string;
}

export interface IShipmentEntry {
  alertCounter: number;
  carrierName?: string;
  clientName?: string;
  containerCount: number;
  createdAt: string;
  customer: {
    name: string;
    shortName: string;
  };
  customerId: number;
  destinationCountryId: number;
  docCutOffDate?: string;
  documentsCount: number;
  unreadEmailCount?: number;
  readEmailCount?: number;
  etdGoal?: string;
  id: number;
  incomingOrderId?: number;
  incotermId: number;
  leadTime: string;
  lastAction: string;
  lastUpdatedTs: string;
  nextAction: string;
  originCountryId: number;
  progress?: number;
  recommendationCounter?: number;
  shipmentDestinationCountry: ICountry;
  shipmentOriginCountry: ICountry;
  shipmentOwner: number;
  status: string;
  tag?: string;
  totalStep?: number;
  tradeLaneId: number;
  transportMode: { id: number; name: TransportModeName };
  transportModeId: number;
  incomingOrder: {
    uniqueIdentifier: string;
    deliveryQuantity?: string | null;
  };
  uniqueIdentifierType: string;
  user: {
    email: string;
    name: string;
    profileUrl: string;
  };
  // Only for schaeffler
  inboundDeliveryNumber?: string;
}
export interface IConsolidatedShipment {
  shipments: IShipmentEntry[];
  name: string;
  id: number;
}
export interface IShipmentTableRow {
  origin: string;
  destination: string;
  consolidatedShipments: IConsolidatedShipment[];
  shipments: IShipmentEntry[];
  hasMore?: boolean;
  shipmentsCount: number;
  consolidationsCount: number;
}

export interface IShipmentTableData {
  shipments: {
    [key: string]: {
      consolidatedShipments: IConsolidatedShipment[];
      shipments: IShipmentEntry[];
      hasMore?: boolean;
      shipmentsCount?: number;
      consolidationsCount?: number;
    };
  };
  count: number;
  skip: number;
  take: number;
  destinationCountries: IDestinationCountryFilter[];
  originCountries: IOriginCountryFilter[];
  modeOfTransports: IModeOfTransportFilter[];
}

export type IShipmentStatusRow = {
  lastAction?: string;
  id: number;
  incomingOrder: {
    uniqueIdentifier: string;
    deliveryQuantity?: string | null;
  };
  shipmentTracking: string;
  destination: string;
  changeInEta: boolean;
  carrierName: string;
  trackingEvent: string;
  ddIndicator: string;
  ddCost: string;
  etdGoal: Date;
  etaNew: Date;
  lastUpdatedTs: string;
  dateOfEvent: string;
  scheduleStatus: string;
  shipmentType: ShipmentType;
  dispatches?: any[];
};

export interface IShipmentIds {
  id: string;
  uniqueIdentifier: string;
}

export interface IShipmentDocumentUpdate {
  documentId: number;
  documentTypeId?: number;
  reclassified?: boolean;
  userShipmentStepId?: number;
  extractedValues?: any;
}

export interface IShipmentAlert {
  id: number;
  shipmentDocId: number;
  alertTs: string;
  alertMessage: string;
  type: "error" | "success";
  isRead: boolean;
  shipmentId: number;
}

export interface IRecommendationItem {
  id: number;
  shipmentId: number;
  specialRule: {
    createdAt: string;
    customer: {
      name: string;
    };
    userInput: string;
  };
}

export interface ShipmentStep {
  metadata: TradeLaneStepMetadata;
  shipmentMetadata: ShipmentStepMetadata;
  name: string;
  stepSequence: number;
  isStepChecked: boolean;
  id: number;
  substeps: ShipmentSubstep[];
  shipments: any[];
  isCommonStep?: boolean;
  allShipmentsChecked?: boolean;
  anyShipmentsChecked?: boolean;
}

export interface ShipmentSubstep {
  id: number;
  metadata: TradeLaneStepMetadata;
  shipmentMetadata: ShipmentSubStepMetadata;
  name: string;
  stepSequence: number;
  isStepChecked?: boolean; //Added only for FE
  shipments?: { id: number; isStepChecked: boolean; shipmentId: number }[]; //Only for consolidated
  isCommonStep?: boolean;
  allShipmentsChecked?: boolean;
  anyShipmentsChecked?: boolean;
}
export interface ShipentHaulageItem {
  provider: { providerEmail?: string; providerId?: number; providerName?: string };
  shipmentId: number;
  tracking?: { trackingNumber?: string; eta?: string };
}
export interface ConsolidatedMetaDataHaulage {
  data: ShipentHaulageItem[];
}
export interface HaulageValue {
  provider?: { providerEmail?: string; providerId?: number; providerName?: string };
  tracking?: { trackingNumber?: string; eta?: string };
}
export interface ShipmentStepMetadata {
  widget?:
    | "create-shipment"
    | "step"
    | "book-shipment"
    | "inbound-haulage"
    | "outbound-haulage"
    | "load-plan"
    | "verified-grossmass"
    | "standalone"
    | "transport-document";
  value?: boolean | HaulageValue | ConsolidatedMetaDataHaulage;
  uiFriendlyName?: string;
  nameFixed?: boolean;
}

export interface ShipmentSubStepMetadata {
  widget:
    | "document"
    | "booking-confirmation-document"
    | "shipment-instructions"
    | "outbound-haulage"
    | "verified-grossmass";
  value: string;
  uiFriendlyName?: string;
  nameFixed?: boolean;
}

export interface ShipmentDocumentType {
  id: number;
  type: string;
  categorized: boolean;
  named: boolean;
  countryType: null;
  extracted_type: string;
  groupName: string;
  userFriendlyName: string;
  order: number;
  countryDocumentTypeReferences: {
    id: number;
    originCountryId: number;
    destCountryId: number;
    documentTypeId: number;
    documents: {
      id: number;
      countryDocTypeRefId: number;
      issuingBody: null | string;
      name: null | string;
      static: boolean;
      createdAt: string;
    }[];
  }[];
}

export interface LoadPlanItem {
  id?: number;
  shipmentId: number;
  shipmentDocumentId?: number | null;
  color?: string;
  name: string;
  length: string;
  width: string;
  height: string;
  weight: string;
  quantity: number;
  isStackable: boolean | null;
  isTiltable: boolean | null;
  action?: string;
  userId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface LoadPlanCalculationContainer {
  name: string;
  index?: number; //Added by us to display on UI
  id: number;
  uuid: string;
  base_type: string;
  L: number;
  W: number;
  H: number;
  WT: number;
  payload: number;
  items_bb: {
    min: {
      x: number;
      y: number;
      z: number;
    };
    max: {
      x: number;
      y: number;
      z: number;
    };
  };
  items: {
    isStackable?: boolean; //Added on FE
    isTiltable?: boolean; //Added on FE
    H: number;
    L: number;
    W: number;
    WT: number;
    bb: {
      max: {
        x: number;
        y: number;
        z: number;
      };
      min: {
        x: number;
        y: number;
        z: number;
      };
    };
    color: string;
    h: number;
    id: number;
    l: number;
    label: string;
    orientations: number;
    pos: {
      x: number;
      y: number;
      z: number;
    };
    qty: number;
    rotation: [number, number, number, "XYZ"];
    w: number;
    wt: number;
  }[];
  volume: number;
  items_count: number;
  tare: number;
  cost: number;
  notes: string;
  door: {
    W: number;
    H: number;
    longside: boolean;
  };
  set_uuid: null;
}
export interface LoadPlanCalculationResponse {
  solutions: {
    containers: LoadPlanCalculationContainer[];
    unloaded_items: [];
    sets: {
      name: string;
      type_id: number;
      uuid: string;
      containers: {
        position: {
          x: number;
          y: number;
          z: number;
        };
        rotation: [number, number, number, "XYZ"];
        uuid: string;
        position_name: null;
      }[];
      payload: null;
      max_volume: null;
      cost: number;
    }[];
  }[];
  status: "success";
}

export interface ShipmentInstructions {
  products: {
    UNIT?: string;
    QUANTITY?: string;
    UNIT_PRICE?: string;
    DESCRIPTION?: string;
    TOTAL_PRICE?: string;
    PRODUCT_CODE?: string;
  }[];
  consigneeName?: string;
  consigneeAddress?: string;
  notifierName?: string;
  notifierAddress?: { country?: { officialName: string } };
  pickupName?: string;
  pickupAddress?: string;
  incoterm?: string;
  netWeight?: number;
  weightUnit?: string;
  forwarderDetails?: {
    icon: "sea" | "air" | "land";
    value: {
      forwarderId: number;
      forwarderName: string;
      forwarderEmail: string;
    }[];
    widget: string;
  }[];
  equipmentDetails?: string[];
  total_cbm: number;
}

export interface DocumentClassificationItem {
  error?: string;
  needsClassification?: boolean;
  doc?: IShipmentDocument;
  isPackingList?: boolean;
  isDeleted?: boolean;
  isReclassified?: boolean;
  file?: File;
  thumbnail?: string;
  shipmentId?: number;
  documentTypeId?: number;
  documentType?: string;
  docId?: number;
  location?: string;
  isCommonDoc?: boolean;
  id?: number;
  userShipmentStepId?: number;
}
export interface IGetConsolidateShipmentsRequest {
  originCountryId?: number;
  destinationCountryId?: number;
  modeOfTransportId?: number;
  shippingOperationType: string;
}
export interface IGetConsolidateShipmentsResponseItem {
  uniqueIdentifier: string;
  id: number;
  isConsolidated: boolean;
  name: string;
  tag: string;
  clientName: string;
  incomingOrder: {
    uniqueIdentifier: string;
    deliveryQuantity?: string | null;
  };
}

export interface IPostConsolidateShipmentRequest {
  name: string;
  originCountryId: number;
  destinationCountryId: number;
  transportModeId: number;
  shipments: { shipmentId: number }[];
  consolidatedOrders: number[];
}

export enum OrderType {
  consolidation = "consolidation",
  standalone = "standalone",
}
