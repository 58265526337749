import Input from "holocene-components/common/new/Input";
import { useGetAllUserRolesByModule } from "holocene-hooks/users.hooks";
import SingleSelect from "holocene-components/common/new/Select";
import { ModeOfTransportMultiSelect } from "holocene-components/common/new/ModeOfTransportDD";
import { useCountries } from "holocene-providers/common";
import { useEffect, useMemo, useState } from "react";
import { CustomerUser } from "holocene-services/user.service";
import {
  CountrySelectMenuOption,
  MultipleCountrySelector,
} from "holocene-components/common/CountrySelector";
import { TransportModeOptionV2 } from "holocene-components/common/new/TransportIcon";
import { REGEX } from "holocene-constants/regex";
import { ModuleEnum } from "./UserDetailsModal";

export type Props = {
  user?: CustomerUser;
  moduleName: ModuleEnum;
  isSameUser: boolean;
  userName?: string;
  setUserName: (value: string | undefined) => void;
  email?: string;
  setEmail: (value: string | undefined) => void;
  role?: number;
  setRole: (value: number | undefined) => void;
  countriesHandled: CountrySelectMenuOption[];
  setCountriesHandled: (value: CountrySelectMenuOption[]) => void;
  transportationModesHandled: TransportModeOptionV2[];
  setTransportationModesHandled: (value: TransportModeOptionV2[]) => void;
  setIsValid: (value: boolean) => void;
  setIsDirty: (value: boolean) => void;
};

export const UserOverview: React.FC<Props> = ({
  user,
  moduleName,
  isSameUser,
  userName,
  setUserName,
  email,
  setEmail,
  role,
  setRole,
  countriesHandled,
  setCountriesHandled,
  transportationModesHandled,
  setTransportationModesHandled,
  setIsValid,
  setIsDirty,
}) => {
  const { countries } = useCountries();
  const countryOptions = useMemo(
    () => countries.map((c) => ({ label: c.officialName, value: c.id, alphaCode: c.alpha2Code })),
    [countries]
  );
  const { data: roles } = useGetAllUserRolesByModule(moduleName);
  const roleOptions = roles?.map((roleInfo) => {
    return { label: roleInfo.displayName, value: roleInfo.id, moduleName: roleInfo.moduleName };
  });

  const [isEmailDirty, setIsEmailDirty] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const isValidEmail = (input: string) => REGEX.EMAIL.test(input);

  const checkValid = () => {
    const isEmailValid = isValidEmail(email ?? "");
    const isNewUser = !user;
    const isValid = isEmailValid && !!userName && (!isNewUser || (isNewUser && !!role));
    setIsValid(isValid);
  };

  useEffect(() => {
    checkValid();
  }, [userName, email, role]);

  return (
    <>
      <div className="flex space-x-2 justify-between mt-3">
        <div className="flex-1">
          <Input
            label="Email*"
            value={email || ""}
            onChange={(evt) => {
              setIsDirty(true);
              const newEmail = evt.target.value ?? "";
              setEmail(newEmail);
              setIsEmailDirty(true);
            }}
            onBlur={() => {
              if (!isEmailDirty) return;
              const isEmailValid = isValidEmail(email ?? "");
              if (email && isEmailValid) {
                delete errors.email;
              } else if (!email) {
                errors.email = "Email is a required field";
              } else {
                errors.email = "Invalid email";
              }
              setErrors({ ...errors });
            }}
            name="email"
            disabled={!!user?.email}
            error={errors.email}
          />
        </div>
      </div>
      <div className="flex space-x-2 justify-between mt-3">
        <div className="flex-1">
          <Input
            label="Name*"
            value={userName || ""}
            onChange={(evt) => {
              setIsDirty(true);
              const newName = evt.target.value ?? "";
              setUserName(newName);
              if (newName.trim()) {
                delete errors.name;
              } else {
                errors.name = "Name is a required field";
              }
              setErrors(errors);
            }}
            name="name"
            error={errors.name}
          />
        </div>
        <div className="w-6" />
        <div className="flex-1">
          {roleOptions && (
            <SingleSelect
              label={`Role${user ? "" : "*"}`}
              options={roleOptions}
              onChange={(roleOption) => {
                setIsDirty(true);
                setRole(roleOption.value);
              }}
              value={role}
              disabled={isSameUser}
            />
          )}
        </div>
      </div>
      <div className="flex space-x-2 justify-between mt-3">
        <div className="flex-1">
          <div className="block text-xs text-secondary-text font-semibold mb-1 whitespace-nowrap">
            Countries Handled
          </div>
          <MultipleCountrySelector
            onMenuClose={() => {}}
            containerStyles={"mt-1 inline-block min-w-[242px]"}
            menuStyle={{ zIndex: 1 }}
            countries={countryOptions}
            selectedValues={countriesHandled}
            onChange={(data) => {
              setIsDirty(true);
              setCountriesHandled(data);
            }}
            placeholder="-"
            showCount
            showAll
          />
        </div>
        <div className="w-6" />
        <div className="flex-1">
          <ModeOfTransportMultiSelect
            onChange={(options) => {
              setIsDirty(true);
              setTransportationModesHandled(options);
            }}
            value={transportationModesHandled}
            label="MOTs Handled"
          />
        </div>
      </div>
    </>
  );
};
