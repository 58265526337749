import apiInstance from "holocene-services/axios";
import { OutboundLogistics } from "holocene-services/delivery.service/types";
import {
  CreateLogisticsProviderRequest,
  LogisticsProvidersResponse,
  LSPDetails,
  ModeOfTransport,
  PatchLSPPayload,
} from "./type";
import { BOOKING_TYPE } from "holocene-components/delivery/BookingForms/constants";
import deliveryService from "holocene-services/delivery.service";

class LogisticsService {
  async updateLogistics(id: number, params: Partial<OutboundLogistics>) {
    const response = await apiInstance.patch<{ logisticsData: OutboundLogistics }>(
      `delivery/${id}/outbound-logistics-data`,
      params
    );
    return response.data;
  }

  async updateConsolidatedLogistics(id: number, body: Partial<OutboundLogistics>) {
    const response = await apiInstance.patch<{ logisticsData: OutboundLogistics }>(
      `consolidated-delivery/${id}/outbound-logistics-data`,
      body
    );
    return response.data;
  }

  async updateLogisticsAndBookTransportation(
    id: number,
    params: Partial<OutboundLogistics>,
    logisticsServiceProviderId: number,
    bookingService: BOOKING_TYPE
  ) {
    await this.updateLogistics(id, params);

    const booking = await deliveryService.bookTransportation({
      id,
      logisticsServiceProviderId,
      bookingService,
    });

    return booking;
  }

  async getModesOfTransportations() {
    const response = await apiInstance.get<ModeOfTransport[]>(`logistics/transportation-modes`);
    return response.data;
  }

  async getLogisticProviders() {
    const response = await apiInstance.get(`logistics-provider`);
    return response.data as LogisticsProvidersResponse;
  }

  async postCreateLogisticsProvider(payload: CreateLogisticsProviderRequest) {
    const response = await apiInstance.post("logistics-provider/available-countries", payload);
    return response.data.map((e: any) => ({
      ...e,
      charges: e.charges ? JSON.parse(e.charges) : null,
      freightCharges: e.freightCharges ? JSON.parse(e.freightCharges) : null,
    }));
  }

  async getLogisticsProvider(clspAvailableCountryId: number | string) {
    return apiInstance.get(`logistics-provider/${clspAvailableCountryId}`).then(
      (res) =>
        ({
          ...res.data,
          charges: res.data.charges ? JSON.parse(res.data.charges) : null,
          freightCharges: res.data.freightCharges ? JSON.parse(res.data.freightCharges) : null,
        } as LSPDetails)
    );
  }

  async patchLogisticsProvider(body: PatchLSPPayload) {
    return apiInstance.patch(`logistics-provider`, {
      ...body,
      charges: body.charges ? JSON.stringify(body.charges) : undefined,
    });
  }

  async deleteLogisticsProvider(id: number) {
    return apiInstance.delete(`logistics-provider/${id}`);
  }

  async createLSP(body: { name: string }) {
    return apiInstance.post("logistics-provider", body).then((res) => res.data);
  }
  async createLSPService(body: { name: string; lspId: number }) {
    return apiInstance.post("logistics-provider/service", body).then((res) => res.data);
  }

  async getCustomerNames() {
    return apiInstance.get("/logistics/customer-names").then((res) => res.data as string[]);
  }
}

export default new LogisticsService();
