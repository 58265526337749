import { Router } from "next/router";
import React, { createContext, FC, useContext, useEffect, useState } from "react";

export interface IGlobalSearchContext {
  searchText: string | undefined;
  setSearchText: (text: string | undefined) => void;
}

export const GlobalSearchContext = createContext<IGlobalSearchContext>({
  searchText: undefined,
  setSearchText: () => {},
});

export const useGlobalSearch = () => {
  const context = useContext(GlobalSearchContext);
  if (context === undefined) {
    throw new Error("useGlobalSearch must be used within a GlobalSearchProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const GlobalSearchProvider: FC<Props> = (props) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  useEffect(() => {
    const resetSearchText = (target: string) => {
      if (target !== "/search") setSearchText(undefined);
    };

    Router.events.on("routeChangeComplete", resetSearchText);

    return () => Router.events.off("routeChangeComplete", resetSearchText);
  }, []);

  return (
    <GlobalSearchContext.Provider value={{ searchText, setSearchText }}>
      {props.children}
    </GlobalSearchContext.Provider>
  );
};
