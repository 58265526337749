import { InputHTMLAttributes, ReactNode } from "react";

export const Radio = ({
  disabled,
  label,
  className,
  ...rest
}: InputHTMLAttributes<any> & { label: string | ReactNode }) => {
  const id = `${rest.name}-${rest.value}`;

  return (
    <label
      htmlFor={id}
      className={`flex gap-2 items-center ${className} ${disabled ? "opacity-50" : ""}`}
    >
      <input
        id={id}
        type="radio"
        className={`rounded-full text-holocene-blue border-primary-border focus:ring-0 focus:ring-transparent h-5 w-5 ${
          disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
        {...rest}
        disabled={disabled}
      ></input>
      <div className="text-sm">{label}</div>
    </label>
  );
};
