import { AxiosRequestHeaders } from "axios";
import { getLocalStorageItem } from "holocene-utils/common.utils";

export function operationModuleHeader(): AxiosRequestHeaders {
  try {
    const urlActiveOperation = window.location.href.replace(/https?:\/\//, "").split("/")[1];
    const activeOperation =
      urlActiveOperation === "sales"
        ? "sales"
        : urlActiveOperation === "purchase"
        ? "purchase"
        : getLocalStorageItem("activeOperation");

    if (typeof activeOperation !== "string") throw new Error("Active operation module not found");

    return { "X-Operation-Module": activeOperation };
  } catch {
    return {};
  }
}
