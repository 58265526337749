import DeliveryTaskList from "holocene-components/workspace/common/DeliveryTaskList/DeliveryTaskList";
import { useRouter } from "next/router";
import React, { createContext, FC, useContext, useState } from "react";

export interface DeliveryTaskListContext {
  openListWithDeliveryId: (open: boolean, id: number | null) => void;
}

export const DeliveryTaskListContext = createContext<DeliveryTaskListContext>({
  openListWithDeliveryId: () => {},
});

export const useDeliveryTaskList = () => {
  const context = useContext(DeliveryTaskListContext);
  if (context === undefined) {
    throw new Error("useDeliveryTaskList must be used within a DeliveryTaskListProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const DeliveryTaskListProvider: FC<Props> = (props) => {
  const [isDeliveryTaskListOpen, setIsDeliveryTaskListOpen] = useState(false);
  const [deliveryId, setDeliveryId] = useState<number | null>(null);

  const openListWithDeliveryId = (open: boolean, id: number | null) => {
    setDeliveryId(id);
    setIsDeliveryTaskListOpen(open);
  };

  return (
    <DeliveryTaskListContext.Provider
      value={{
        openListWithDeliveryId,
      }}
    >
      {props.children}
      <DeliveryTaskList
        deliveryId={deliveryId}
        show={isDeliveryTaskListOpen}
        onClose={() => setIsDeliveryTaskListOpen(false)}
      />
    </DeliveryTaskListContext.Provider>
  );
};
