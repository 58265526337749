import Button from "holocene-components/common/Button";
import Modal from "holocene-components/common/Modal";

import { INotificationTypes, useNotification } from "holocene-providers/common";
import { ButtonGroup } from "holocene-components/common/new/ButtonGroup";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { UserOverview } from "./UserOverview";
import { UserPermissions } from "./UserPermissions";
import { CustomerUser } from "holocene-services/user.service/types";
import { CountrySelectMenuOption } from "holocene-components/common/CountrySelector";
import { TransportModeOptionV2 } from "holocene-components/common/new/TransportIcon";
import {
  useCreateNewUser,
  useGetAllUserPermissionsByModule,
  useUpdateUserInformation,
} from "holocene-hooks/users.hooks";
import CustomSwitch from "holocene-components/common/CustomSwitch";
import { isDevelopmentOrStagingEnv } from "holocene-utils/common.utils";

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Permissions", value: "permissions" },
];

export enum ModuleEnum {
  sales = "sales",
  purchase = "purchase",
}

export type Props = {
  setShowModal: (state: boolean) => void;
  moduleName: ModuleEnum;
  editData?: CustomerUser;
  currentUserId?: number;
};

const UserDetailsModal: React.FC<Props> = (props) => {
  const { setShowModal, editData, moduleName } = props;

  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const mutationUserUpdate = useUpdateUserInformation();
  const mutationUserCreate = useCreateNewUser();
  const [section, setSection] = useState<"overview" | "permissions">("overview");
  const { data: allPermissions } = useGetAllUserPermissionsByModule(props.moduleName);

  const [userName, setUserName] = useState(editData?.name);
  const [email, setEmail] = useState(editData?.email);
  const [role, setRole] = useState(editData?.roles?.[0]?.Role.id);

  const [assignDeliveriesSales, setAssignDeliveriesSales] = useState(
    editData?.assignDeliveriesSales ?? false
  );
  const [assignDeliveriesPurchase, setAssignDeliveriesPurchase] = useState(
    editData?.assignDeliveriesPurchase ?? false
  );

  const [countriesHandled, setCountriesHandled] = useState<CountrySelectMenuOption[]>(
    editData?.userAssignedCounties?.map(({ userAssignedCountry }) => ({
      value: userAssignedCountry.id,
      label: userAssignedCountry.officialName,
      alphaCode: userAssignedCountry.alpha2Code,
    })) ?? []
  );
  const [transportationModesHandled, setTransportationModesHandled] = useState<
    TransportModeOptionV2[]
  >(
    editData?.UserAssignedModesOfTransportation?.map(({ TransportationMode }) => ({
      value: TransportationMode.id,
      label: TransportationMode.type,
    })) ?? []
  );
  const defaultPermissions = editData
    ? editData.permissions?.map(({ FeaturePermission, readable, writable }) => ({
        id: FeaturePermission.id,
        readable: readable,
        writable: writable,
      }))
    : allPermissions?.map(({ id, readable, writable }) => ({ id, readable, writable }));

  const [assignedPermissions, setAssignedPermissions] = useState(defaultPermissions ?? []);

  const [isDirty, setIsDirty] = useState(false);
  const [isOverviewValid, setIsOverviewValid] = useState(false);

  useEffect(() => {
    const updatedPermissions = editData
      ? editData.permissions?.map(({ FeaturePermission, readable, writable }) => ({
          id: FeaturePermission.id,
          readable: readable,
          writable: writable,
        }))
      : allPermissions?.map(({ id, readable, writable }) => ({ id, readable, writable }));
    setAssignedPermissions(updatedPermissions ?? []);
  }, [editData, allPermissions]);

  const onSave = () => {
    mutationUserUpdate.mutate(
      {
        userId: Number(editData?.userId),
        requestData: {
          name: userName?.trim(),
          email: email?.trim(),
          role: role,
          countriesHandled: countriesHandled?.map(({ value }) => Number(value)),
          transportationModesHandled: transportationModesHandled?.map(({ value }) => value),
          assignedPermissions: assignedPermissions.map(({ id, readable, writable }) => ({
            permissionId: id,
            readable,
            writable,
          })),
          assignDeliveriesSales: assignDeliveriesSales,
          assignDeliveriesPurchase: assignDeliveriesPurchase,
          moduleName: props.moduleName,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_CONSTANTS.CUSTOMER_USERS,
          });
          if (editData?.userId && editData.userId === props.currentUserId) {
            await queryClient.invalidateQueries({
              queryKey: QUERY_CONSTANTS.CURRENT_USER_INFO,
            });
          }
          setShowModal(false);
        },
        onError: (err: any) => {
          const errMessage = err?.response?.data?.message ?? "Server error";
          addNotification({ type: INotificationTypes.Error, message: errMessage });
        },
      }
    );
  };

  const onCreate = () => {
    mutationUserCreate.mutate(
      {
        requestData: {
          name: userName?.trim(),
          email: email?.trim(),
          role: role,
          countriesHandled: countriesHandled?.map(({ value }) => Number(value)),
          transportationModesHandled: transportationModesHandled?.map(({ value }) => value),
          assignedPermissions: assignedPermissions.map(({ id, readable, writable }) => ({
            permissionId: id,
            readable,
            writable,
          })),
          assignDeliveriesSales: assignDeliveriesSales,
          assignDeliveriesPurchase: assignDeliveriesPurchase,
          moduleName: props.moduleName,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: QUERY_CONSTANTS.CUSTOMER_USERS,
          });
          await queryClient.invalidateQueries(QUERY_CONSTANTS.USERS);
          setShowModal(false);
        },
        onError: (err: any) => {
          const errMessage = err?.response?.data?.message ?? "Server error";
          addNotification({ type: INotificationTypes.Error, message: errMessage });
        },
      }
    );
  };

  return (
    <Modal
      title={props.editData ? `Edit User` : `Add User`}
      showModal
      setShowModal={() => setShowModal(false)}
      className="bg-white mt-8 mb-8"
    >
      <div>
        <ButtonGroup
          options={tabs}
          value={section}
          onChange={(val) => setSection(val as "overview" | "permissions")}
          className="mb-4"
        />
        {section === "overview" && (
          <UserOverview
            user={editData}
            isSameUser={!!props.currentUserId && props.currentUserId === editData?.userId}
            moduleName={props.moduleName}
            userName={userName}
            setUserName={setUserName}
            email={email}
            setEmail={setEmail}
            role={role}
            setRole={setRole}
            countriesHandled={countriesHandled}
            setCountriesHandled={setCountriesHandled}
            transportationModesHandled={transportationModesHandled}
            setTransportationModesHandled={setTransportationModesHandled}
            setIsDirty={setIsDirty}
            setIsValid={setIsOverviewValid}
          />
        )}
        {section === "permissions" && (
          <UserPermissions
            user={editData}
            assignedPermissions={assignedPermissions}
            moduleName={props.moduleName}
            setAssignedPermissions={setAssignedPermissions}
            setIsDirty={setIsDirty}
          />
        )}
      </div>
      <div className="mt-8 mb-4 flex">
        {section === "overview" &&
          (moduleName === ModuleEnum.sales ? (
            <CustomSwitch
              label="Assign Deliveries"
              checked={assignDeliveriesSales ?? false}
              className="text-secondary-text text-sm ml-2"
              onChange={(checked: boolean) => {
                setIsDirty(true);
                setAssignDeliveriesSales(checked);
              }}
            />
          ) : (
            <CustomSwitch
              label="Assign Deliveries"
              checked={assignDeliveriesPurchase ?? false}
              className="text-secondary-text text-sm ml-2"
              onChange={(checked: boolean) => {
                setIsDirty(true);
                setAssignDeliveriesPurchase(checked);
              }}
            />
          ))}
        <div className="grow"></div>
        <Button bgColor="bg-white text-primary-text" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button
          className="ml-2.5"
          onClick={() => {
            if (editData) {
              onSave();
            } else {
              onCreate();
            }
          }}
          disabled={
            mutationUserUpdate.isLoading ||
            mutationUserCreate.isLoading ||
            !isOverviewValid ||
            !isDirty
          }
        >
          {editData ? "Update" : "Add"}
        </Button>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
