import { InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import shipmentService, {
  IGetShipmentsRequest,
  IRecommendationItem,
  IShipmentAlert,
  IShipmentDocument,
  IShipmentEntry,
  IShipmentTableData,
  IShipmentTableRow,
  packingListVersions,
} from "holocene-services/shipment.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CountrySelectMenuOption } from "holocene-components/common/CountrySelector";
import {
  extractShipmentsAndCountFromQuery,
  extractShipmentsAndCountFromQuery2,
  updateShipmentQueryPages,
} from "holocene-utils/shipment.utils";
import {
  IConsolidatedShipment,
  IGetConsolidateShipmentsRequest,
  IGetShipmentsWithStatusRequest,
  IShipment,
  OrderType,
  ShipmentStep,
  ShipmentWithStatus,
} from "holocene-services/shipment.service/types";
import {
  useAlertWebSocket,
  WebsocketAlertAction,
  WebsocketAlertModelName,
  WebsocketAlert,
} from "./alertWebSocket.hooks";
import { useShipmentDocumentContext } from "holocene-providers/shipments/DocumentsProvider";
import { TransportModeOption } from "holocene-components/common/new/TransportIcon";
import { CustomerDocumentTemplate } from "holocene-services/settings.service/types";
import { INotificationTypes, useNotification } from "holocene-providers/common";
import {
  MappedAvailabletDocuments,
  UserStepIdDocMap,
} from "holocene-components/shipment-queue/ShipmentSteps/common.types";
import { convertFileToPdfBlob } from "holocene-utils/helpers";
import { globalSearchService } from "holocene-services/globalSearch.service";
const TRANSPORT_DOCS = ["Airway Bill", "airway_bill", "Air", "CMR", "Land"];
export const TRANSPORT_DOCS_ALL = [
  "Airway Bill",
  "airway_bill",
  "Air",
  "CMR",
  "Land",
  "Bill of Lading",
  "bill_of_lading ",
  "Sea",
];
const shipmentSelector = (response: IShipment) => {
  let addedSteps: { index: number; step: ShipmentStep }[] = [];
  response.availableDocsByStepsId = getAvailableDocsByStepsId(response, false);

  response.userShipmentSteps.forEach((step: ShipmentStep, stepIndex) => {
    step.shipmentMetadata =
      step.shipmentMetadata && typeof step.shipmentMetadata === "string"
        ? JSON.parse(step.shipmentMetadata)
        : step.shipmentMetadata;
    let shiftedSubStepIndices: number[] = [];
    step.substeps.forEach((subStep, ssIndex) => {
      subStep.shipmentMetadata =
        subStep.shipmentMetadata && typeof subStep.shipmentMetadata === "string"
          ? JSON.parse(subStep.shipmentMetadata)
          : subStep.shipmentMetadata;
    });
    for (let i = shiftedSubStepIndices.length - 1; i >= 0; i--) {
      const ind = shiftedSubStepIndices[i];
      step.substeps.splice(ind, 1);
    }
  });
  for (let i = addedSteps.length - 1; i >= 0; i--) {
    const { step, index } = addedSteps[i];
    response.userShipmentSteps.splice(index, 0, step);
  }
  return response;
};
const getAvailableDocsByStepsId = (shipment: IShipment, isConsolidated: boolean) => {
  if (!isConsolidated) {
    return shipment.availableDocTypes?.reduce((accum: MappedAvailabletDocuments, doc, index) => {
      if (accum[doc.userShipmentStepId]) {
        accum[doc.userShipmentStepId].push(doc);
      } else {
        accum[doc.userShipmentStepId] = [doc];
      }
      return accum;
    }, {});
  }
  return shipment.availableDocTypesConsolidated?.reduce(
    (accum: MappedAvailabletDocuments, doc, index) => {
      (doc as any).availableDocTypes.forEach((item: any) => {
        if (accum[item.userShipmentStepId]) {
          accum[item.userShipmentStepId].push(item);
        } else {
          accum[item.userShipmentStepId] = [item];
        }
      });
      return accum;
    },
    {}
  );
};
const consolidatedShipmentSelector = (response: IShipment) => {
  let docTypeShipmentMap: any = {};
  response.availableDocsByStepsId = getAvailableDocsByStepsId(response, true);
  let userStepIdDocMap: UserStepIdDocMap = {};
  response.shipmentDocuments.forEach((shipment) => {
    (shipment as any).documents.forEach((doc: any) => {
      if (!docTypeShipmentMap[doc?.documentTypeId]) {
        docTypeShipmentMap[doc.documentTypeId] = [];
      }
      if (!userStepIdDocMap[doc.userShipmentStepId]) {
        userStepIdDocMap[doc.userShipmentStepId] = [];
      }
      userStepIdDocMap[doc.userShipmentStepId] = [
        ...userStepIdDocMap[doc?.userShipmentStepId],
        doc,
      ];

      docTypeShipmentMap[doc.documentTypeId] = [...docTypeShipmentMap[doc?.documentTypeId], doc];
    });
  });
  response.docTypeShipmentMap = docTypeShipmentMap;
  let addedSteps: { index: number; step: ShipmentStep }[] = [];
  response.userConsolidatedOrderSteps.forEach((step: ShipmentStep, stepIndex) => {
    step.shipmentMetadata =
      step.shipmentMetadata && typeof step.shipmentMetadata === "string"
        ? JSON.parse(step.shipmentMetadata)
        : step.shipmentMetadata;
    step.allShipmentsChecked = step.shipments?.every((i) => i.isStepChecked);
    step.anyShipmentsChecked = step.shipments?.some((i) => i.isStepChecked);

    let shiftedSubStepIndices: number[] = [];
    step.substeps.forEach((subStep, ssIndex) => {
      subStep.allShipmentsChecked = subStep.shipments?.every((i) => {
        return i.isStepChecked || userStepIdDocMap?.[i.id];
      });
      subStep.anyShipmentsChecked = subStep.shipments?.some((i) => {
        return i.isStepChecked || userStepIdDocMap?.[i.id];
      });

      subStep.shipmentMetadata =
        subStep.shipmentMetadata && typeof subStep.shipmentMetadata === "string"
          ? JSON.parse(subStep.shipmentMetadata)
          : subStep.shipmentMetadata;
    });
    for (let i = shiftedSubStepIndices.length - 1; i >= 0; i--) {
      const ind = shiftedSubStepIndices[i];
      step.substeps.splice(ind, 1);
    }
  });
  for (let i = addedSteps.length - 1; i >= 0; i--) {
    const { step, index } = addedSteps[i];
    response.userConsolidatedOrderSteps.splice(index, 0, step);
  }
  return response;
};

const useGetShipmentInfo = (shipmentId: number) => {
  return useQuery([QUERY_CONSTANTS.SHIPMENT_SERVICE_INFO, shipmentId], () =>
    shipmentService.loadShipmentInfo(shipmentId).then(shipmentSelector)
  );
};

const useGetShipmentAlerts = (shipmentId?: number) => {
  return useQuery<IShipmentAlert[]>([QUERY_CONSTANTS.SHIPMENT_ALERTS, shipmentId], async () => {
    if (!shipmentId) {
      return [];
    }
    return await shipmentService.shipmentAlerts(shipmentId);
  });
};

const useGetShipmentDocTypes = (originId: number, destinationId: number) => {
  return useQuery([QUERY_CONSTANTS.SHIPMENT_DOC_TYPES], () =>
    shipmentService.getDocumentTypes(originId, destinationId)
  );
};

const useGetShipmentRecommendations = (shipmentId?: number, isConsolidated?: boolean) => {
  return useQuery<IRecommendationItem[]>(
    [QUERY_CONSTANTS.SHIPMENT_RECOMMENDATIONS, shipmentId],
    async () => {
      if (!shipmentId) {
        return [];
      }
      return isConsolidated
        ? shipmentService.getConsolidatedShipmentRecommendations(shipmentId)
        : shipmentService.recommendations(shipmentId);
    },
    {
      refetchOnMount: "always",
    }
  );
};
export const useGetFileBlob = ({
  location,
  onError,
}: {
  location: string | null;
  onError: () => void;
}) => {
  return useQuery(
    [QUERY_CONSTANTS.SHIPMENT_FILE, location],
    () =>
      location
        ? shipmentService.getShipmentDocumentsFile(location).then((res) => {
            return convertFileToPdfBlob(res.data);
          })
        : Promise.resolve(null),
    {
      onError: () => {
        onError();
      },
    }
  );
};
const useGetShipmentFileBlob2 = ({
  file,
  onError,
}: {
  file: null | IShipmentDocument;
  onError: (file: IShipmentDocument) => void;
}) => {
  return useQuery(
    [QUERY_CONSTANTS.SHIPMENT_FILE, file?.location],
    () => (file?.location ? shipmentService.getShipmentFileBlob(file) : Promise.resolve(null)),
    {
      onError: () => {
        onError(file!);
      },
    }
  );
};
const useGetShipmentFileBlobForTempalte = ({ file }: { file: null | CustomerDocumentTemplate }) => {
  return useQuery([QUERY_CONSTANTS.CUSTOMER_FILE_BLOB, file?.location], () => {
    return file?.location ? shipmentService.getFileBlob(file) : Promise.resolve(null);
  });
};

const useCountShipments = () => {
  return useQuery([QUERY_CONSTANTS.SHIPMENT_COUNT], () => shipmentService.getShipmentCount(), {
    enabled: false,
  });
};

const useGetShipments = (params: IGetShipmentsRequest) => {
  const { data, fetchNextPage, isLoading, refetch, isFetching, isFetchingNextPage } =
    useInfiniteQuery([QUERY_CONSTANTS.SHIPMENTS_LIST, params], ({ pageParam }) =>
      shipmentService.getShipments({ ...params, ...pageParam })
    );

  const commonPage = data?.pages[0];

  const lastPageCount = (() => {
    const page = data?.pages?.slice(-1)?.[0];
    if (!page) return 0;
    if (!page.shipments) return 0;
    const ln = Object.keys(page.shipments);
    return ln.length;
  })();

  const lastParams = (() => {
    const dt = data?.pageParams?.slice(-1)?.[0];
    if (!dt) return { skip: 0 };
    return dt as { skip: number };
  })();

  const originCountryOptions: CountrySelectMenuOption[] = useMemo(
    () =>
      (commonPage?.originCountries || []).map(({ shipmentOriginCountry }) => ({
        label: shipmentOriginCountry.officialName,
        value: shipmentOriginCountry.id,
        alphaCode: shipmentOriginCountry.alpha2Code,
      })),
    [commonPage]
  );

  const destinationCountryOptions: CountrySelectMenuOption[] = useMemo(
    () =>
      (commonPage?.destinationCountries || []).map(({ shipmentDestinationCountry }) => ({
        label: shipmentDestinationCountry.officialName,
        value: shipmentDestinationCountry.id,
        alphaCode: shipmentDestinationCountry.alpha2Code,
      })),
    [commonPage]
  );

  const modeOfTransportOptions: TransportModeOption[] = useMemo(
    () => (commonPage?.modeOfTransports || []).map(({ id, name }) => ({ label: name, value: id })),
    [commonPage]
  );

  /**This function returns the grouped shipments in the required tabular format. It also
   * returns the count of the fetched shipments so far.
   */
  const { count, shipments }: { count: number; shipments: IShipmentTableRow[] } = useMemo(
    () => extractShipmentsAndCountFromQuery(data?.pages || []),
    [data?.pages]
  );

  return {
    shipments,
    originCountryOptions,
    destinationCountryOptions,
    modeOfTransportOptions,
    isLoading: isLoading || isFetching,
    fetchNextPage,
    isFetchingNextPage,
    totalCount: commonPage?.count || 0,
    fetchedCount: count,
    refetch,
    lastParams,
    lastPageCount,
  };
};
const useGetShipments2 = (params: IGetShipmentsRequest) => {
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery([QUERY_CONSTANTS.SHIPMENTS_LIST_NEW, params], () =>
    shipmentService.getShipments2({ ...params })
  );
  const modeOfTransportOptions: TransportModeOption[] = useMemo(
    () => (data?.modeOfTransports || []).map(({ id, name }) => ({ label: name, value: id })),
    [data]
  );

  const destinationCountryOptions: CountrySelectMenuOption[] = useMemo(
    () =>
      (data?.destinationCountries || []).map(({ shipmentDestinationCountry }) => ({
        label: shipmentDestinationCountry.officialName,
        value: shipmentDestinationCountry.id,
        alphaCode: shipmentDestinationCountry.alpha2Code,
      })),
    [data]
  );
  const originCountryOptions: CountrySelectMenuOption[] = useMemo(
    () =>
      (data?.originCountries || []).map(({ shipmentOriginCountry }) => ({
        label: shipmentOriginCountry.officialName,
        value: shipmentOriginCountry.id,
        alphaCode: shipmentOriginCountry.alpha2Code,
      })),
    [data]
  );

  const { count, shipments }: { count: number; shipments: IShipmentTableRow[] } = useMemo(
    () => extractShipmentsAndCountFromQuery2(data?.shipments || []),
    [data]
  );
  const locallyUpdateShipments = useCallback(
    (
      shipmentId: number,
      options: { payload?: IShipmentEntry; isDeleted?: boolean; appendData?: IShipmentTableData }
    ) => {
      queryClient.setQueryData([QUERY_CONSTANTS.SHIPMENTS_LIST_NEW, params], (oldData: any) => {
        if (oldData) {
          const updatedPages = updateShipmentQueryPages(oldData, shipmentId, options);
          return { ...updatedPages };
        }
        return { pages: [], pageParams: [] };
      });
    },
    [params, queryClient]
  );
  return {
    shipments,
    isLoading,
    refetch,
    totalCount: data?.count || 0,
    modeOfTransportOptions,
    originCountryOptions,
    destinationCountryOptions,
    locallyUpdateShipments,
  };
};

export const useGetShipmentsWithStatusCount = (params: IGetShipmentsWithStatusRequest) => {
  const { data, isLoading } = useQuery(
    [QUERY_CONSTANTS.SHIPMENTS_IN_TRANSIT_COUNT, params],
    ({ pageParam }) => shipmentService.getShipmentsWithStatus({ ...params, ...pageParam }),
    { refetchOnMount: true, staleTime: 0 }
  );
  return { totalCount: data?.count, isLoading };
};

const useGetShipmentsWithStatus = (params: IGetShipmentsWithStatusRequest) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery(
    [QUERY_CONSTANTS.SHIPMENTS_IN_TRANSIT, params],
    ({ pageParam }) => shipmentService.getShipmentsWithStatus({ ...params, ...pageParam }),
    { refetchOnMount: true, staleTime: 0 }
  );

  const shipmentRows = useMemo(
    () =>
      data?.pages.reduce((arr: ShipmentWithStatus[], page) => [...arr, ...page.shipments], []) ||
      [],
    [data]
  );

  const lastPageCount = (() => {
    const page = data?.pages?.slice(-1)?.[0];
    if (!page) return 0;
    if (!page.shipments) return 0;
    const ln = Object.keys(page.shipments);
    return ln.length;
  })();

  const lastParams = (() => {
    const dt = data?.pageParams?.slice?.(-1)?.[0];
    if (!dt) return { skip: 0 };
    return dt as { skip: number };
  })();

  const totalCount = data?.pages[0].count;
  return { data: shipmentRows, totalCount, fetchNextPage, isLoading, lastParams, lastPageCount };
};

const useShipmentDocumentAlerts = (
  shipment: IShipment,
  initialValue?: IShipmentAlert[],
  noFetch?: boolean,
  isConsolidated?: boolean,
  afterDocCreateAlert?: (docs?: string[]) => void
) => {
  const shipmentId = shipment?.id;

  const {
    resetContext,
    setLoadPlanDisabled,
    setGenerateTemplateData,
    setReclassifyDocs,
    setSelectedDocument,
    reclassifyDocs,
  } = useShipmentDocumentContext();
  const [alerts, setAlerts] = useState(initialValue || []);
  const { data: apiAlerts } = useGetShipmentAlerts(noFetch ? undefined : shipmentId);
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (apiAlerts && apiAlerts.length > 0) {
      setAlerts(apiAlerts);
    }
  }, [apiAlerts]);

  const docTypesConsolidated = useMemo(() => {
    if (isConsolidated) {
      const uniqueDocTypeIds: number[] = [];
      const output: any[] = [];
      shipment.availableDocTypesConsolidated.forEach((entry) => {
        entry.availableDocTypes.forEach((e) => {
          if (!uniqueDocTypeIds.includes(e.docTypes[0].id)) {
            uniqueDocTypeIds.push(e.docTypes[0].id);
            output.push({
              ...e.docTypes[0],
              userShipmentStepId: e.userShipmentStepId,
              isCustomDocType: !Boolean(e.docTypes[0].id),
            });
          }
        });
      });

      return output;
    }
  }, [isConsolidated, shipment]);

  const handleCommonDocUpload = async (
    classificationItems: IShipmentDocument[],
    transportdocs?: string[]
  ) => {
    const updatePromises: Promise<any>[] = [];
    classificationItems.forEach((item) => {
      if (isConsolidated) {
        const defaultConsolidatedShipmentId = shipment.consolidatedOrderShipments?.[0].shipmentId;
        const payload = {
          reclassified: true,
          documentTypeId: item.documentTypeId,
          shipmentId: item.shipmentId || defaultConsolidatedShipmentId,
          documentId: item.id!,
          location: item.location,
          userShipmentStepId: docTypesConsolidated?.filter(
            (doc) => doc.id === item.documentTypeId
          )?.[0]?.userShipmentStepId,
        };
        updatePromises.push(shipmentService.updateShipmentDocuments(payload, isConsolidated));
      }
    });

    await Promise.all([...updatePromises])
      .then(async (res) => {
        if (!transportdocs?.length) {
          if (isConsolidated) {
            await queryClient.invalidateQueries([
              QUERY_CONSTANTS.CONSOLIDATED_SHIPMENT_DETAIL,
              shipment.id,
            ]);
          } else {
            await queryClient.invalidateQueries([
              QUERY_CONSTANTS.SHIPMENT_SERVICE_INFO,
              shipment.id,
            ]);
          }
          setTimeout(() => {
            setSelectedDocument(res[res.length - 1] || null);
          }, 100);
        }

        addNotification({
          type: INotificationTypes.Success,
          message: `${res.length} document${res.length > 1 ? "s" : ""} uploaded`,
        });
      })
      .catch((err) => {
        addNotification({
          type: INotificationTypes.Error,
          message: err.response?.data?.message || "Something went wrong",
        });
      });
  };

  const afterDocs = async (uploadedDocs: IShipmentDocument[], transportdocs?: string[]) => {
    const uploadedItems: IShipmentDocument[] = [];
    const commonDocs: IShipmentDocument[] = [];
    let reclassifyRequired = false;
    uploadedDocs.forEach((document: any) => {
      if (document.hasError) {
        addNotification({ type: INotificationTypes.Error, message: document.message });
      } else {
        if (!document.userShipmentStepId) reclassifyRequired = true;
        if (document.isCommonDoc) commonDocs.push(document);
        uploadedItems.push(document);
        if (packingListVersions.includes(document?.extractedValues?.doc_type))
          setLoadPlanDisabled(true);
      }
    });
    if (isConsolidated && reclassifyRequired && commonDocs.length === uploadedItems.length) {
      reclassifyRequired = false;
      handleCommonDocUpload(commonDocs, transportdocs);
      return;
    }
    if (reclassifyRequired) {
      setReclassifyDocs([...reclassifyDocs, ...uploadedItems]);
    } else if (uploadedItems.length > 0) {
      if (isConsolidated) {
        await queryClient.invalidateQueries([
          QUERY_CONSTANTS.CONSOLIDATED_SHIPMENT_DETAIL,
          shipment.id,
        ]);
      } else {
        if (!transportdocs?.length) {
          await queryClient.invalidateQueries([QUERY_CONSTANTS.SHIPMENT_SERVICE_INFO, shipment.id]);
        }
      }
      if (!(!isConsolidated && transportdocs?.length)) {
        setSelectedDocument(uploadedItems[uploadedItems.length - 1]);
      }
    }
  };

  const onAlert = useCallback(
    (alert: WebsocketAlert) => {
      if (alert.modelName === WebsocketAlertModelName.SHIPMENT_DOCUMENT_ALERT) {
        if (alert.action === WebsocketAlertAction.DELETE_MANY) {
          const alertData = alert.data as IShipmentAlert[];
          const deletedIds = alertData.map((item) => item.id);
          setAlerts((p) => p.filter((item) => !deletedIds.includes(item.id)));
        } else {
          const alertData = alert.data as IShipmentAlert;
          if (alertData.shipmentId === shipmentId) {
            switch (alert.action) {
              case WebsocketAlertAction.CREATE:
                setAlerts((p) => [...p, alertData]);
                break;
              case WebsocketAlertAction.UPDATE:
                setAlerts((p) => p.map((item) => (item.id === alertData.id ? alertData : item)));
                break;
              case WebsocketAlertAction.UPSERT:
                setAlerts((p) => [...p, alertData]);
                break;
              case WebsocketAlertAction.DELETE:
                setAlerts((p) => p.filter((item) => item.id !== alertData.id));
                break;
            }
          }
        }
        resetContext();
      } else if (
        alert.modelName === WebsocketAlertModelName.SHIPMENT_DOCUMENT &&
        alert.action === WebsocketAlertAction.UPDATE
      ) {
        setLoadPlanDisabled(false);
        if (alert?.extractedValues?.doc_type === "Sales Order") {
          setGenerateTemplateData(alert?.extractedValues.entities);
        } else if (alert?.extractedValues?.doc_type === "Bill of Lading") {
        }
      } else if (
        alert.modelName === WebsocketAlertModelName.BUNDLE_DOCS &&
        alert.action === WebsocketAlertAction.CREATE
      ) {
        const docs: string[] = [];
        const currentShipmentDocs: IShipmentDocument[] = [];
        if (!isConsolidated) {
          alert.bundleDocs.forEach((document: any) => {
            if (document.shipmentId !== shipmentId) {
              return;
            }
            if (document.hasError) {
              return;
            }
            const isTransportDocument = TRANSPORT_DOCS_ALL.includes(
              document.extractedValues?.doc_type
            );
            if (isTransportDocument) {
              docs.push(document.extractedValues?.doc_type);
            }
            currentShipmentDocs.push(document);
          });
        } else {
          alert.bundleDocs.forEach((document: any) => {
            if (document.consolidatedOrderId !== shipmentId) {
              return;
            }
            if (document.hasError) {
              return;
            }
            currentShipmentDocs.push(document);

            //Bill of ladding is not common doc so its handled in classification modal
            const isTransportDocument = TRANSPORT_DOCS.includes(document.documentType);
            if (isTransportDocument) {
              docs.push(document.documentExtractedType || document.documentType);
            }
          });
        }
        afterDocCreateAlert?.(docs);

        afterDocs(currentShipmentDocs, docs);
      }
    },
    [
      resetContext,
      shipmentId,
      setLoadPlanDisabled,
      setGenerateTemplateData,
      isConsolidated,
      afterDocCreateAlert,
      afterDocs,
    ]
  );

  useAlertWebSocket(onAlert);

  return alerts;
};

const useGetShipmentLoadPlanCalculations = (id: number, type: OrderType) => {
  return useQuery(
    [QUERY_CONSTANTS.SHIPMENT_LOAD_PLAN_CALCULATE, id],
    () => shipmentService.getLoadPlanCalculation(id, type),
    { retry: 5, retryDelay: 5000 }
  );
};

const useGetConsolidateShipments = (params: IGetConsolidateShipmentsRequest) => {
  const { data, isLoading } = useQuery(
    [QUERY_CONSTANTS.SHIPMENT_LOAD_PLAN_CALCULATE, params],
    () => shipmentService.getConsolidateShipments(params),
    { refetchOnMount: true, retry: false }
  );
  return { data: data?.data || [], isLoading: isLoading };
};

const useGetConsolidatedShipmentInfo = (shipmentId: number) => {
  return useQuery([QUERY_CONSTANTS.CONSOLIDATED_SHIPMENT_DETAIL, shipmentId], () =>
    shipmentService.loadConsolidatedShipmentInfo(shipmentId).then((res) =>
      consolidatedShipmentSelector({
        ...res,
        userShipmentSteps: res.userConsolidatedOrderSteps,
        shipmentOriginCountry: res.originCountry,
        shipmentDestinationCountry: res.destinationCountry,
      })
    )
  );
};

export const useGetGlobalSearchResults = (search: string, status: "pending" | "dispatched") => {
  return useInfiniteQuery(
    [QUERY_CONSTANTS.SHIPMENTS_SEARCH, search, status],
    ({ pageParam }) =>
      globalSearchService.getGlobalSearchByText(
        search,
        status,
        pageParam || { take: 100, skip: 0 }
      ),
    { enabled: Boolean(search) }
  );
};

export {
  useGetShipmentInfo,
  useGetShipmentAlerts,
  useGetShipmentDocTypes,
  useGetShipmentRecommendations,
  useGetShipmentFileBlob2,
  useGetShipmentFileBlobForTempalte,
  useCountShipments,
  useGetShipments,
  useGetShipmentsWithStatus,
  useGetShipmentLoadPlanCalculations,
  useShipmentDocumentAlerts,
  useGetConsolidateShipments,
  useGetConsolidatedShipmentInfo,
  useGetShipments2,
  useGetFileBlob as useGetThumbnail,
};
