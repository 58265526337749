import { ISelectOption } from "holocene-components/trade-lanes/types";

export const ButtonGroup = ({
  options,
  disabled = false,
  value,
  onChange,
  className,
  buttonClass = "",
}: {
  options: ISelectOption[];
  disabled?: boolean;
  value: string | number;
  onChange: (value: string | number) => void;
  className?: string;
  buttonClass?: string;
}) => {
  const defaultClassName = "bg-white text-sm p-2.5 ";
  const activeClassName = "bg-table-bg text-holocene-blue ";
  const trailingElementsClassname = "border-l border-l-table-border-color";
  return (
    <div
      className={`grid grid-cols-${options.length} border border-table-border-color rounded-lg overflow-hidden ${className}`}
    >
      {options.map((opt, index) => (
        <button
          key={opt.value}
          type="button"
          disabled={disabled}
          className={`${defaultClassName} ${buttonClass} ${
            opt.value === value && activeClassName
          } ${index > 0 && trailingElementsClassname}`}
          onClick={() => onChange(opt.value)}
        >
          {opt.label}
        </button>
      ))}
    </div>
  );
};
