export interface ICreateDeliveryTaskRequest {
  task: string;
  dueDate?: string | null;
  userId?: number;
}

export enum IDeliveryTaskStatus {
  Open = "open",
  Completed = "completed",
}

export enum IDeliveryTaskType {
  DueToday = "due-today",
  Expired = "expired",
  Upcoming = "upcoming",
  All = "all",
}

export interface IDeliveryTask {
  id: number;
  task: string;
  status: IDeliveryTaskStatus;
  userId: number;
  user?: {
    id: number;
    name: string;
  };
  salesDeliveryId: number;
  salesDelivery: {
    id: number;
    deliveryNumber: string;
    salesOrder: {
      soNumber: string;
    };
  };
  updatedAt: string;
  createdAt: string;
  dueDate: string;
}
